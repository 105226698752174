import React from "react";
import groovyWalkAnimation from "./hello-bee.json";
import Lottie from "lottie-react";
import { Box, Center } from "@chakra-ui/react";
export default function Loader() {
  return (
    <>
      <Center>
        <Box mt={"20vh"} h={"20vh"} w={"20vh"}>
          <Lottie
            height={400}
            width={400}
            animationData={groovyWalkAnimation}
            loop={true}
          />
        </Box>
      </Center>
    </>
  );
}
