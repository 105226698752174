import { Box, Center, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import SideButton from "./SideButton";
import { MdDashboard, MdCalendarToday, MdDateRange } from "react-icons/md";
import { AiOutlineBranches } from "react-icons/ai";
import { IoMdSchool } from "react-icons/io";
import { FaSchool } from "react-icons/fa";
import { MdOutlineMessage } from "react-icons/md";
import { Logo } from "../../utilities/components/Logo";
import { Link } from "react-router-dom";
import { StoreManagerRoutes } from "../Data";

const MobileDrawer = () => {
  return (
    <VStack
      pt={8}
      alignItems={"flex-start"}
      spacing={6}
      bg={"black"}
      h={"100vh"}
    >
      <Stack
        bg={"black"}
        px="5%"
        direction="row"
        justify={"space-evenly"}
        pb="7px"
      >
        <Link to="/" bg={"black"}>
          <Logo />
        </Link>
        <HStack
          display={{ base: "flex", md: "none" }}
          spacing={1}
          bg={"black"}
          borderRadius={"5px"}
          px={2}
          py={1}
        >
          <MdDateRange color="gray.900" />
          <Text color={"gray.700"} fontSize={15} fontWeight={"normal"}>
            {new Date().toDateString().substring(4, 15)}
          </Text>
        </HStack>
      </Stack>
      {StoreManagerRoutes.map((adminRoute) => (
        <SideButton to={adminRoute?.to} name={adminRoute?.name}>
          {adminRoute?.icon}
        </SideButton>
      ))}
      {/* <SideButton to="/Dashboard" name="Dashboard">
        <MdDashboard size={"1.7em"} />
      </SideButton> */}
      {/* <SideButton to="/Calender" name="Academic calendar">
        <MdCalendarToday size={"1.7em"} />
      </SideButton>
      <SideButton to="/Manage_branch" name="Manage branch">
        <AiOutlineBranches size={"1.7em"} />
      </SideButton>
      <SideButton to="/Manage_students" name="Manage students">
        <IoMdSchool size={"1.7em"} />
      </SideButton>
      <SideButton to="/Manage_school" name="Manage school">
        <FaSchool size={"1.7em"} />
      </SideButton>
      <SideButton to="/Broadcast_message" name="Broadcast message">
        <MdOutlineMessage size={"1.7em"} />
      </SideButton> */}
    </VStack>
  );
};

export default MobileDrawer;
