import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import axios from "axios";
import MaterialTable from "material-table";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../utilities/hooks/auth-hook";
import { BsThreeDots } from "react-icons/bs";
import { BiShowAlt } from "react-icons/bi";
import { RiEdit2Line, RiDeleteBin6Line } from "react-icons/ri";
import Loader from "../../../assets/Loader";

export default function TransfersTable() {
  const { useState } = React;
  const { token } = useAuth();
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  function DropMenu({ rowData }) {
    return (
      <Menu>
        <MenuButton
          rounded={"sm"}
          size={"xs"}
          as={IconButton}
          aria-label="Options"
          icon={
            <IconButton
              size={"xs"}
              rounded={"sm"}
              icon={<BsThreeDots />}
              variant={"outline"}
              colorScheme={"blackAlpha"}
            />
          }
          variant="outline"
        />
        <MenuList>
          <MenuItem
            icon={<BiShowAlt />}
            onClick={() =>
              navigate("/transfers/CreateTransfers", {
                state: {
                  state: rowData,
                  isReadOnly: true,
                  isEdit: false,
                  isShow: true,
                },
              })
            }
          >
            Show
          </MenuItem>
          <MenuItem
            icon={<RiEdit2Line />}
            onClick={() =>
              navigate("/transfers/CreateTransfers", {
                state: {
                  state: rowData,
                  isReadOnly: false,
                  isEdit: true,
                  isShow: false,
                },
              })
            }
          >
            Edit
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }
  const getTransfers = useQuery(
    "getTransfers",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}transfers`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setData(res.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  console.log({ getTransfers });
  const [columns, setColumns] = useState([
    { title: "From", field: "from.name" },
    {
      title: "To ",
      field: "to.name",
    },

    {
      title: "Amount ",
      field: "money_amount",
    },
    {
      title: "Action",
      render: (rowData) => {
        return <DropMenu rowData={rowData} />;
      },
    },
  ]);

  // const [data, setData] = useState([
  //   {
  //     grand: "test",
  //     code: 11111,
  //     color: "red",
  //     price: "100",
  //     quantity: "222,000,000",
  //     image: "test",
  //   },
  //   {
  //     grand: "test",
  //     code: 2222,
  //     color: "green",
  //     price: "100",
  //     quantity: "104,455,005",
  //     image: "test",
  //   },
  //   {
  //     grand: "test",
  //     code: 33333,
  //     color: "blue",
  //     price: "100",
  //     quantity: "222,000,000",
  //     image: "test",
  //   },
  // ]);
  return (
    <Box w={"100%"}>
      {getTransfers?.isFetching ? (
        <Loader />
      ) : (
        <MaterialTable
          title="Transfers List"
          columns={columns}
          data={data}
          options={{
            filtering: true,

            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "#E82481",
              color: "#FFF",
            },
          }}
        />
      )}
    </Box>
  );
}
