import { Box, Center, Heading, Stack, Text, Wrap } from "@chakra-ui/react";
import React from "react";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";

const RevenueComposition = () => {
  const inpersonVsOnline = [
    { name: "Money", value: 400000 },
    { name: "Month", value: 30000 },
  ];

  const inpersonVsReferral = [
    { name: "In Person", value: 4000000 },
    { name: "Referal", value: 3000000 },
  ];

  const paymentModes = [
    { name: "Check", value: 400000 },
    { name: "Transfer", value: 70000 },
    { name: "Cash", value: 300000 },
    { name: "Telebirr", value: 200000 },
  ];

  const COLORS1 = ["#0088FE", "#00C49F"];
  const COLORS2 = ["#0088FE", "#00C49F"];
  const COLORS3 = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const RADIAN = Math.PI / 180;

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize={"12px"}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  return (
    <Stack spacing={"18px"}>
      <Heading pl="5%"> Share Composition </Heading>
      <Wrap justify={"center"} align="center" width="100%" height="100%">
        <Stack>
          <Center>Payable Dividend Vs Plowedback</Center>
          <PieChart width={320} height={300}>
            <Pie
              dataKey="value"
              // isAnimationActive={false}
              data={inpersonVsOnline}
              cx="50%"
              cy="30%"
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#E82481"
            />
            {inpersonVsOnline.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS1[index % COLORS1.length]}
              />
            ))}
            <Tooltip />
          </PieChart>
        </Stack>
        <Stack>
          <Center>Total Revenue Vs Payable Dividend</Center>
          <PieChart width={320} height={300}>
            <Pie
              dataKey="value"
              // isAnimationActive={false}
              data={inpersonVsReferral}
              cx="50%"
              cy="30%"
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#31C736"
            />
            {inpersonVsReferral.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS1[index % COLORS1.length]}
              />
            ))}
            <Tooltip />
          </PieChart>
        </Stack>
        {/* <Stack>
          <Center>Payment Modes</Center>
          <PieChart width={320} height={300}>
            <Pie
              dataKey="value"
              // isAnimationActive={false}
              data={paymentModes}
              cx="50%"
              cy="30%"
              outerRadius={80}
              fill="#31C736"
              label={renderCustomizedLabel}
            />

            <Tooltip />
          </PieChart>
        </Stack> */}
      </Wrap>
    </Stack>
  );
};

export default RevenueComposition;
