import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  Stack,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import FormComp from "../../utilities/components/FormComp";
import * as Yup from "yup";
import Select from "react-select";

import { useAuth } from "../../utilities/hooks/auth-hook";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Formik, FieldArray } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
export default function Createsharegrowth() {
  const [selectedOption, setselectedOption] = React.useState([]);
  const [options, setoptions] = React.useState([]);
  const [selectedDate, handleDateChange] = React.useState(new Date());
  const [year, setyear] = useState("");
  const minDate = new Date(Date.now());
  const handleChange = (selectedOption) => {
    setselectedOption(selectedOption);
  };
  const navigate = useNavigate();
  const { token } = useAuth();
  const toast = useToast();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const dividendMutation = useMutation(
    async (newData) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "capital-growths",
        newData,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );
  const DividendMutationHandler = async (values) => {
    const { percentage, capitals } = values;
    console.log({ selectedOption });
    try {
      dividendMutation.mutate(
        {
          percentage,
          capitals,
        },
        {
          onSuccess: (responseData) => {
            toast({
              title: "capital-growths created successfully",
              status: "success",
              duration: 2500,
              isClosable: true,
            });
            navigate("/Capital-growth");
          },
          onError: (err) => {
            console.log(err);
            toast({
              title: "capital-growths not created.",
              status: "error",
              duration: 2500,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {
      console.log(err);
      toast({
        title: "Error when Creating",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };
  const toSqlDatetime = (inputDate) => {
    var dd = String(inputDate.getDate()).padStart(2, "0");
    var mm = String(inputDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = inputDate.getFullYear();
    inputDate = yyyy + "-" + mm + "-" + dd;
    return inputDate;
  };
  return (
    <Stack
      bg={"white"}
      boxShadow={"base"}
      w={"100%"}
      p={8}
      spacing={6}
      justify={"center"}
      align={"center"}
    >
      <Box w={"80%"}>
        <Heading mb={"40px"}>Create percentage</Heading>
        <Formik
          initialValues={{
            percentage: "",
            capitals: [
              {
                start_date: selectedDate,

                share_growth: "",
              },
            ],
          }}
          validationSchema={Yup.object({
            // nameid: Yup.string().required("Required !"),
            percentage: Yup.string().required("Required !"),

            capitals: Yup.array().of(
              Yup.object({
                start_date: Yup.string(),
                // end_date: Yup.string(),
                share_growth: Yup.string(),
              })
            ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            DividendMutationHandler(values);
            console.log({ values });
            setSubmitting(false);
          }}
        >
          {(formik) => {
            console.log({ formik });
            return (
              <form onSubmit={formik.handleSubmit}>
                <>
                  <FormComp
                    id={"percentage"}
                    label={" Total percentage"}
                    formik={formik}
                  />

                  <div>
                    <Center fontWeight={"bold"}>
                      {" "}
                      Add Total growth share{" "}
                    </Center>
                    <hr></hr>
                    <FieldArray
                      name="capitals"
                      render={(arrayHelpers) => (
                        <div>
                          {formik.values.capitals &&
                            formik.values.capitals.length > 0 &&
                            formik.values.capitals.map((capitals, index) => (
                              <Stack key={index}>
                                <Stack
                                  mt={4}
                                  direction={{
                                    base: "column",
                                    md: "column",
                                    lg: "row",
                                    xl: "row",
                                  }}
                                >
                                  <Box>
                                    <Text textAlign={"left"}> Start Date</Text>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <KeyboardDatePicker
                                        clearable
                                        views={["year"]}
                                        minDate={minDate}
                                        selectsRange={true}
                                        id={`capitals${index}.start_date`}
                                        value={
                                          formik.values.capitals[index]
                                            .start_date
                                        }
                                        placeholder="Start Date"
                                        onChange={(value) => {
                                          // console.log();
                                          formik.setFieldValue(
                                            `capitals[${index}].start_date`,
                                            toSqlDatetime(value)
                                          );
                                        }}
                                        // onChange={(date) => handleDateChange(date)}
                                        // minDate={new Date()}
                                        format="dd/MM/yyyy"
                                      />
                                    </MuiPickersUtilsProvider>
                                  </Box>
                                  {/* <Box>
                                    <Text textAlign={"left"}> End Date</Text>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <KeyboardDatePicker
                                        clearable
                                        selectsRange={true}
                                        value={
                                          formik.values.capitals[index].end_date
                                        }
                                        id={`capitals.${index}.end_date`}
                                        placeholder="End Date"
                                        onChange={(value) => {
                                          // console.log();
                                          formik.setFieldValue(
                                            `capitals[${index}].end_date`,
                                            toSqlDatetime(value)
                                          );
                                        }}
                                        // onChange={(date) => handleDateChange(date)}
                                        // minDate={new Date()}
                                        format="dd/MM/yyyy"
                                      />
                                    </MuiPickersUtilsProvider>
                                  </Box> */}
                                </Stack>
                                <FormComp
                                  id={`capitals.${index}.share_growth`}
                                  label={"share_growth_percentage"}
                                  formik={formik}
                                />
                                <hr></hr>
                              </Stack>
                            ))}
                          <Center py={2}>
                            <HStack>
                              <IconButton
                                onClick={() => arrayHelpers.push("")}
                                size={"sm"}
                                icon={<AiFillPlusCircle size={30} />}
                                rounded={"full"}
                                colorScheme={"blue"}
                              />
                              <IconButton
                                onClick={() => arrayHelpers.pop("")}
                                size={"sm"}
                                icon={<AiFillMinusCircle size={30} />}
                                rounded={"full"}
                                colorScheme={"blue"}
                              />
                            </HStack>
                          </Center>
                        </div>
                      )}
                    />
                  </div>
                </>
                {
                  <Button
                    disabled={formik.isSubmitting}
                    mt={6}
                    type="submit"
                    bg={"#B76A1D"}
                    color={"white"}
                    variant="solid"
                  >
                    Submit
                  </Button>
                }
              </form>
            );
          }}
        </Formik>
      </Box>
    </Stack>
  );
}
