import React from "react";
import { Center, Heading, Stack, Text } from "@chakra-ui/react";
import { ProxyTable } from "./ProxyTable";
export const Proxy = () => {
  return (
    <Stack padding={10}>
      <Heading margin={""} textAlign={"center"} fontSize="xl">
        Proxy Management
      </Heading>{" "}
      <Center>
        <ProxyTable />
      </Center>
    </Stack>
  );
};
