import {
  useToast,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
  Center,
  Text,
  Box,
  HStack,
  Td,
  InputGroup,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  PhoneIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import axios from "axios";
import MaterialTable from "material-table";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../utilities/hooks/auth-hook";
import { BsThreeDots } from "react-icons/bs";
import { BiShowAlt } from "react-icons/bi";
import { RiEdit2Line, RiDeleteBin6Line } from "react-icons/ri";
import Loader from "../../../assets/Loader";
import { IoMdTennisball } from "react-icons/io";

export default function AverageTable() {
  const { useState } = React;
  const { token } = useAuth();
  let navigate = useNavigate();
  const [Id, setId] = useState(null);
  const [IdT, setIdT] = useState(null);
  const [query, setquery] = useState("");

  const [data, setData] = useState([]);
  const [selectedIndex, setselectedIndex] = useState(0);

  const toast = useToast();
  // const [count, setCount] = useState(0);
  // const [queryReload, setQueryReload] = useState(false);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getAverage = useQuery(
    "getAverage",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}dividend-log`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setData(res.data.data);
        var dataAdp = 0;

        for (var i = 0; i < res?.data?.data?.length; i++) {
          if (dataAdp <= res?.data?.data[i]?.dividend_log.length) {
            dataAdp = res?.data?.data[i]?.dividend_log.length;
            setselectedIndex(i);
            console.log(i);
          }
        }
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  console.log({ getAverage });
  const [columns, setColumns] = useState([
    { title: "Start Date", field: "start_date" },
    { title: "End Date", field: "end_date" },
    {
      title: " Profit Amount",
      field: "money_amount",
    },
    {
      title: " Remaining_Share_amount",
      field: "remaining_amount",
    },
    {
      title: "Publish ",
      field: "is_finalized",
    },
  ]);

  // const [data, setData] = useState([
  //   {
  //     grand: "test",
  //     code: 11111,
  //     color: "red",
  //     price: "100",
  //     quantity: "222,000,000",
  //     image: "test",
  //   },
  //   {
  //     grand: "test",
  //     code: 2222,
  //     color: "green",
  //     price: "100",
  //     quantity: "104,455,005",
  //     image: "test",
  //   },
  //   {
  //     grand: "test",
  //     code: 33333,
  //     color: "blue",
  //     price: "100",
  //     quantity: "222,000,000",
  //     image: "test",
  //   },
  // ]);
  return (
    <Box padding={3} w={"100%"}>
      <InputGroup maxW={{ base: "30vh", md: "40vh" }} mb={5}>
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon color="gray.300" />}
        />
        <Input
          type="tel"
          placeholder="Search by Name"
          onChange={(e) => setquery(e.target.value)}
        />
      </InputGroup>

      {getAverage.isFetching ? (
        <Text>Laoding....</Text>
      ) : (
        <>
          <Box
            border={"1px solid rgba(0, 0, 0, 0.05)"}
            maxW={"100%"}
            bg={"white"}
            overflowX={"scroll"}
            style={{
              overflowY: "scroll",
              height: "82vh",
            }}
          >
            {" "}
            <table
              style={{
                border: "1px solid ",

                textAlign: "center",
              }}
            >
              <thead
                style={{
                  position: "sticky",
                  top: "0",
                  border: "1px solid ",

                  background: "white",
                }}
              >
                <tr
                  style={{
                    border: "2px solid ",
                  }}
                >
                  <th
                    style={{
                      border: "1px solid ",
                    }}
                  >
                    NO
                  </th>
                  <th
                    style={{
                      border: "1px solid ",
                      color: "#70A8DD",
                    }}
                  >
                    Name
                  </th>

                  {data[selectedIndex]?.dividend_log?.map((item) => {
                    return (
                      <>
                        <th
                          style={{
                            border: "1px solid ",
                          }}
                        >
                          <Center> Initial Average </Center>
                          <Box height={0.3} w={"100%"} bg={"black"}></Box>
                          <HStack>
                            <td>
                              {" "}
                              <Text w={20}>Date</Text>{" "}
                            </td>
                            <Box height={10} w={0.5} bg={"black"}></Box>
                            <td>
                              {" "}
                              <Text w={20}>Average</Text>{" "}
                            </td>
                          </HStack>
                        </th>
                      </>
                    );
                  })}
                  <th
                    style={{
                      border: "1px solid ",
                    }}
                  >
                    Total Average
                  </th>
                  <th
                    style={{
                      border: "1px solid ",
                    }}
                  >
                    Current_capital
                  </th>
                  <th
                    style={{
                      border: "1px solid ",
                    }}
                  >
                    Dividend
                  </th>
                  <th
                    style={{
                      border: "1px solid ",
                    }}
                  >
                    <Text width={"25vh"}> Paid up - Total average</Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data
                  ?.filter((item) => item.name.includes(query))
                  .map((item, index) => {
                    return (
                      <tr
                        key={index}
                        style={{
                          textAlign: "center",
                          border: "1px solid ",

                          background:
                            item?.current_capital - item?.total_average < 0
                              ? "#f48989"
                              : "transparnt",
                        }}
                      >
                        <td
                          style={{
                            border: "1px solid ",
                            // position: "sticky",
                            // left: "0",
                            // background: "white",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            border: "1px solid ",

                            // position: "sticky",
                            // left: "0",
                            // background: "white",
                          }}
                        >
                          <Button
                            onClick={() => {
                              console.log({ item });
                              navigate("/dividend/Edit", {
                                state: {
                                  name: item?.name,
                                  userId: item?.id,
                                  profitId: item?.dividend_log[0]?.profit_id,
                                },
                              });
                            }}
                            colorScheme="blue"
                            variant="link"
                          >
                            {" "}
                            {item?.name}
                          </Button>
                        </td>

                        {item?.dividend_log.length !== 0 ? (
                          <>
                            {item?.dividend_log.map((data) => {
                              return (
                                <>
                                  <td
                                    style={{
                                      border: "1px solid ",
                                    }}
                                  >
                                    <HStack>
                                      <td>
                                        {" "}
                                        <Text w={20}>
                                          {data?.plowback_created_date}
                                        </Text>{" "}
                                      </td>
                                      <Box
                                        height={12}
                                        w={0.5}
                                        bg={"black"}
                                      ></Box>
                                      <td>
                                        {" "}
                                        <Text w={20}>{data?.average}</Text>{" "}
                                      </td>
                                    </HStack>
                                  </td>
                                </>
                              );
                            })}
                            {[
                              ...Array(
                                data[selectedIndex]?.dividend_log?.length -
                                  item?.dividend_log.length
                              ).keys(),
                            ].map((item) => {
                              return (
                                <>
                                  <td
                                    style={{
                                      border: "1px solid ",
                                    }}
                                  >
                                    <HStack>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                      <Box
                                        height={10}
                                        w={0.3}
                                        bg={"black"}
                                      ></Box>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                    </HStack>
                                  </td>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          data[selectedIndex]?.dividend_log?.map((item) => {
                            return (
                              <>
                                <td
                                  style={{
                                    border: "1px solid ",
                                  }}
                                >
                                  <HStack>
                                    <td>
                                      {" "}
                                      <Text w={20}>-</Text>{" "}
                                    </td>
                                    <Box height={10} w={0.5} bg={"black"}></Box>
                                    <td>
                                      {" "}
                                      <Text w={20}>-</Text>{" "}
                                    </td>
                                  </HStack>
                                </td>
                              </>
                            );
                          })
                        )}
                        <td
                          style={{
                            border: "1px solid ",
                            // position: "sticky",
                            // left: "0",
                            // background: "white",
                          }}
                        >
                          {item?.total_average}
                        </td>
                        <td
                          style={{
                            border: "1px solid ",
                            // position: "sticky",
                            // left: "0",
                            // background: "white",
                          }}
                        >
                          {item?.current_capital}
                        </td>
                        <td
                          style={{
                            border: "1px solid ",
                            // position: "sticky",
                            // left: "0",
                            // background: "white",
                          }}
                        >
                          {item?.dividend}
                        </td>
                        <td
                          style={{
                            border: "1px solid ",
                            // position: "sticky",
                            // left: "0",
                            // background: "white",
                          }}
                        >
                          {item?.current_capital - item?.total_average}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Box>
        </>
      )}
    </Box>
  );
}
