import {
  Box,
  Button,
  Center,
  HStack,
  Img,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { MdDateRange } from "react-icons/md";
import styled from "styled-components";
import BreadCrumb from "./BreadCrumb";
import { VscBellDot } from "react-icons/vsc";
import { MobileIcon } from "./Global.elements";
import { useAuth } from "../hooks/auth-hook";
import { useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { RiLockPasswordFill } from "react-icons/ri";
import { AiOutlineLogout, AiOutlineTransaction } from "react-icons/ai";
import ProfileChangeModal from "./ProfileChangeModal";
import { useQuery } from "react-query";
import axios from "axios";
import { SyncLoader } from "react-spinners";
import TaxUpdate from "./TaxUpdate";
// import PasswordChangeModal from "../../admin/components/PasswordChangeModal";

export default function NaBar({ btnRef, click, handleClick }) {
  //Hook
  const [IsNotfied, setIsNotfied] = useState(false);
  const { token, user, logout } = useAuth();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  //add state          for opening and cllosing tax  modal with useDisclosure
  const {
    isOpen: isOpenTax,
    onOpen: onOpenTax,
    onClose: onCloseTax,
  } = useDisclosure();

  const [passChange, setPassChange] = useState(false);
  const [logoutSwitch, setLogoutSwitch] = useState(false);
  const toast = useToast();
  const [userId, setUserId] = useState(null);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const logOutQuery = useQuery(
    "logOutQuery",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}logout`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token && logoutSwitch,
      onSuccess: (res) => {
        logout();
        setLogoutSwitch(false);
        toast({
          title: "Logged out successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/");
      },
      onError: (res) => {
        setLogoutSwitch(false);
        logout();
        navigate("/");

        toast({
          title: "Not Logged out.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  const currentAcademicYear = useQuery(
    `currentAcademicYear`,
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}years-current`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onError: (res) => {
        if (res?.response?.data?.message === "Unauthenticated.") {
          logout();
          navigate("/login");
          toast({
            title: "LOGIN FIRST TO ACCESS THIS RESOURCE.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else if (res?.message === "Network Error") {
          toast.closeAll();
          toast({
            title: (
              <HStack>
                <Text>Check your internet connection and </Text>
                <Button color={"#000"} onClick={() => window.location.reload()}>
                  Refresh
                </Button>
              </HStack>
            ),
            description: "Seems like there is issue with your connection",
            status: "error",
            duration: null,
          });
          toast.closeAll();
        }
      },
    }
  );

  const onLogout = () => {
    setLogoutSwitch(true);
  };

  const onProfileExit = () => {
    setUserId(null);
    setPassChange(false);
    onClose();
  };

  return (
    <Box w={{ base: "94%", xl: "100%" }}>
      <ProfileChangeModal
        isOpen={isOpen}
        onClose={onProfileExit}
        userId={userId}
      />
      <TaxUpdate isOpen={isOpenTax} onClose={onCloseTax} userId={userId} />
      {/* <PasswordChangeModal isOpen={passChange} onClose={onProfileExit} /> */}

      <HStack
        // justifyContent={"space-between"}
        alignItems={"center"}
        style={{
          boxShadow: `rgba(0, 0, 0, 0.1) 0px 0.5px 0px`,
          position: "sticky",
          top: "0",
        }}
        w={"full"}
        py={3}
        px={4}
        bgColor="white"
        zIndex={500}
      >
        <Box flexGrow={1}>
          <BreadCrumb />
        </Box>
        <HStack spacing={4} align="center" pr={{ base: 6, lg: 6 }}>
          {!currentAcademicYear?.isFetching &&
            currentAcademicYear?.isSuccess && (
              <HStack
                display={{ base: "none", md: "flex" }}
                spacing={1}
                bg={"blue.50"}
                borderRadius={"5px"}
                px={2}
                py={1}
              >
                <MdDateRange color="gray.900" />
                <Text color={"gray.700"} fontSize={15} fontWeight={"normal"}>
                  {/* {currentAcademicYear?.data?.data?.data?.} */}
                  {/* {currentAcademicYear?.isSuccess &&
                  currentAcademicYear &&
                  currentAcademicYear?.data?.data?.data?.starting_at &&
                  currentAcademicYear?.data?.data?.data?.ending_at &&
                  currentAcademicYear?.data?.data?.data?.starting_at.split(
                    "-"
                  )[0] +
                    "/" +
                    currentAcademicYear?.data?.data?.data?.ending_at.split(
                      "-"
                    )[0]} */}
                </Text>
              </HStack>
            )}
          <Separet />
          <VscBellDot color={IsNotfied ? "red" : "blue"} />
          <Separet />
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <Box minW="32px">
                <CgProfile />
              </Box>
            </MenuButton>
            <MenuList
              bg="#FFF"
              p={0}
              fontSize={"16px"}
              minW="100px"
              borderColor="#3F1052"
            >
              <MenuItem display={"none"}></MenuItem>

              <MenuItem
                // bg="#3F1052"
                onClick={() => {
                  onClose();
                  onOpenTax();
                  setUserId(user?.id);
                }}
                _active={{ bg: "#2FC61F", color: "#3F1052" }}
                _hover={{ bg: "#385898", color: "#FFFFFF" }}
              >
                <HStack>
                  <AiOutlineTransaction />
                  <Text>Update Tax </Text>
                </HStack>
              </MenuItem>
              <MenuItem
                // bg="#3F1052"
                onClick={() => {
                  onOpen();
                  setUserId(user?.id);
                }}
                _active={{ bg: "#2FC61F", color: "#3F1052" }}
                _hover={{ bg: "#385898", color: "#FFFFFF" }}
              >
                <HStack>
                  <CgProfile />
                  <Text>Profile</Text>
                </HStack>
              </MenuItem>
              <MenuItem
                // bg="#3F1052"
                onClick={() => {
                  setPassChange(true);
                }}
                _active={{ bg: "#2FC61F", color: "#3F1052" }}
                _hover={{ bg: "#385898", color: "#FFFFFF" }}
              >
                <HStack>
                  <RiLockPasswordFill />
                  <Text>Change password</Text>
                </HStack>
              </MenuItem>
              <MenuItem
                // bg="#3F1052"
                onClick={() => {
                  onLogout() && navigate("/");
                }}
                _active={{ bg: "#2FC61F", color: "#3F1052" }}
                _hover={{ bg: "#385898", color: "#FFFFFF" }}
              >
                <HStack>
                  <AiOutlineLogout />
                  <Center>Logout</Center>
                </HStack>
              </MenuItem>
            </MenuList>
          </Menu>
          <MobileIcon onClick={handleClick} ref={btnRef}>
            {click ? (
              <Img
                w="30px"
                src="https://img.icons8.com/material-outlined/24/000000/delete-sign.png"
              />
            ) : (
              <Img
                w="30px"
                src="https://img.icons8.com/material-outlined/24/000000/menu.png"
              />
            )}
          </MobileIcon>
        </HStack>
      </HStack>
    </Box>
  );
}

const Separet = styled.div`
  height: 20px;
  width: 0.5px;
  border-radius: 200px;
  background-color: lightblue;
`;
