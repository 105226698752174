import React from "react";
import { Button } from "@chakra-ui/button";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Box, Center, Text, VStack } from "@chakra-ui/layout";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Checkbox, Progress } from "@chakra-ui/react";
import MaterialTable from "material-table";
import axios from "axios";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { useMutation, useQuery } from "react-query";
import { useToast } from "@chakra-ui/react";

const validationSchema = Yup.object({
  agendaTitle: Yup.string()
    .required("Agenda title is required")
    .min(6, "must be at least 6 character"),
  agendaDescription: Yup.string()
    .required("Agenda description is required")
    .min(8, "must be at least 8 character"),
});
export const Agenda = () => {
  const { token } = useAuth();
  const [agenda, setAgenda] = React.useState({
    agendaTitle: "",
    agendaDescription: "",
    isNominee: false,
  });
  const toast = useToast();
  const [agendas, setAgendas] = React.useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const params = useParams();
  const [column] = React.useState([
    { title: "Agenda Title", field: "title" },
    { title: "Agenda Description", field: "description" },
    {
      title: "Is Nominee vote",

      render: (rowData) => (
        <Checkbox
          defaultChecked={rowData.is_nominee_vote == 1}
          isDisabled
          iconColor="green.500"
          borderColor="gray.400"
          borderRadius="sm"
          checked={rowData.is_nominee_vote == 1}
          _checked={{
            bg: "green.500",
            borderColor: "green.500",
            color: "white",
          }}
          _focus={{
            boxShadow: "0 0 0 3px rgba(66, 153, 225, 0.6)",
          }}
          _disabled={{
            bg: "green.500",
            borderColor: "green.500",
            color: "white",
            borderRadius: "20px ",
          }}
          size="md"
          px={2}
          py={2}
        />
      ),
    },
    {
      title: "Add Nominee",
      type: "action",
      render: (rowData) =>
        rowData.is_nominee_vote == 1 ? (
          <Button
            sx={{
              color: "gray.900",
              fontSize: "1rem",
              fontWeight: "bold",
              borderColor: "green.500",
            }}
            onClick={() => {
              navigate(`/meeting/${rowData?.id}`, {
                state: { meetingId: params.meetingId },
              });
            }}
          >
            Add Nominee
          </Button>
        ) : null,
    },
    {
      title: "Delete",

      render: (rowData) => (
        <Button
          sx={{
            color: "gray.900",
            fontSize: "1rem",
            fontWeight: "bold",
            borderColor: "red.500",
            backgroundColor: "red.500",
          }}
          onClick={() => {
            deleteAgenda.mutate(rowData?.id);
          }}
        >
          Delete
        </Button>
      ),
    },
  ]);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const agendaData = useQuery(
    "agendaData",
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}agendas/${params?.meetingId}`,

        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );
  React.useEffect(() => {
    if (agendaData?.data?.data) {
      setAgendas(agendaData?.data?.data?.data);
    }
  }, [agendaData?.data?.data]);
  const createAgenda = useMutation(
    async (data) => {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}agendas`, data, {
        headers,
      });
    },
    {
      onSuccess: () => {
        agendaData.refetch();

        toast({
          title: "Agenda created successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        agendaData.refetch();
      },
    }
  );
  const deleteAgenda = useMutation(
    async (id) => {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}agendas/${id}`, {
        headers,
      });
    },
    {
      onSuccess: () => {
        agendaData.refetch();
        toast({
          title: "Agenda deleted successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
      onError: (err) => {
        toast({
          title: `${err?.response?.data?.message}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  return (
    <Box>
      <Text
        fontSize="2xl"
        marginLeft={6}
        fontWeight="bold"
        textAlign="start"
        mb={4}
      >
        Add New Agenda
      </Text>

      <Center
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          fontSize="2xl"
          color={"#E82481"}
          fontWeight="bold"
          textAlign="center"
          mb={4}
        >
          {state?.meetingTittle}
        </Text>

        <Box bg="white" p={6} w={"40%"}>
          <Checkbox
            sx={{
              color: "gray.900",
              fontSize: "1.5rem",
              fontWeight: "bold",
              padding: "2rem",
              borderColor: "green.500",

              _focus: {
                boxShadow: "none",
              },
            }}
            onChange={(e) => {
              setAgenda((prevState) => ({
                ...prevState,
                isNominee: e.target.checked,
              }));
            }}
            value={agenda.isNominee}
            name="radio"
            colorScheme="green"
            my={".5rem"}
          >
            Is Nominee Vote
          </Checkbox>
          <Formik
            initialValues={{
              agendaTitle: "",
              agendaDescription: agenda?.agendaDescription,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              // if (agenda.isNominee) {
              //   values.agendaTitle = "Nominee Vote";
              // }

              createAgenda.mutate({
                title: values.agendaTitle,
                description: values.agendaDescription,
                is_nominee_vote: agenda.isNominee,
                meeting_id: params?.meetingId,
              });
              actions.setSubmitting(false);
              actions.resetForm();
            }}
          >
            {(props) => (
              <VStack
                as={"form"}
                spacing={4}
                align="flex-start"
                onSubmit={props.handleSubmit}
              >
                {/* {agenda?.isNominee ? null : ( */}
                <FormControl
                  isInvalid={
                    !!props.errors.agendaTitle && props.touched.agendaTitle
                  }
                >
                  <FormLabel htmlFor="title">Title</FormLabel>
                  <Field
                    p={6}
                    as={Input}
                    placeholder="Enter  Title of the agenda"
                    id="agendaTitle"
                    name="agendaTitle"
                    variant="filled"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.agendaTitle}
                  />
                  <FormErrorMessage>
                    {" "}
                    {props.errors.agendaTitle}{" "}
                  </FormErrorMessage>
                </FormControl>
                {/* )} */}
                <FormControl
                  isInvalid={
                    !!props.errors.agendaDescription &&
                    props.touched.agendaDescription
                  }
                >
                  <FormLabel htmlFor="description">Description </FormLabel>
                  <Input
                    p={8}
                    as={"textarea"}
                    placeholder="Enter description for the Agenda"
                    id="agendaDescription"
                    name="agendaDescription"
                    variant="filled"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.agendaDescription}
                  />
                  <FormErrorMessage>
                    {props.errors.agendaDescription}
                  </FormErrorMessage>
                </FormControl>

                <Button
                  type="submit"
                  colorScheme="purple"
                  disabled={
                    props.isSubmitting || !props.isValid || !props.dirty
                  }
                  width="full"
                  mt={4}
                >
                  Create Agenda
                </Button>
              </VStack>
            )}
          </Formik>
        </Box>
      </Center>
      <MaterialTable
        isLoading={createAgenda.isLoading}
        components={{
          OverlayLoading: (props) => (
            <Progress size="md" colorScheme="pink" isIndeterminate />
          ),
        }}
        style={{
          margin: "2rem",
          boxShadow: "0 0 10px rgba(0,0,0,0.2)",
        }}
        options={{
          headerStyle: {
            backgroundColor: "#E82481",
            color: "#FFF",
          },
          rowStyle: {
            color: "#000",
            backgroundColor: "#FFF",
          },
          searchFieldStyle: {
            backgroundColor: "#FFF",
            color: "#000",
          },

          padding: "default",
          actionsColumnIndex: -1,
          exportButton: true,
          exportAllData: true,
          exportFileName: "Agenda List",
        }}
        title="Agenda List"
        columns={column}
        data={agendas}
      />
    </Box>
  );
};
