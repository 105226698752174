import { Box, Button, Center, Stack } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import React from "react";
import SatckholdersTable from "./Table/SatckholdersTable";
import { useNavigate } from "react-router-dom";

export default function Shareholder() {
  let navigate = useNavigate();
  return (
    <Stack padding={10}>
      <Box>
        <Button
          mt={"20px"}
          mb={"20px"}
          // ml={"10px"}
          mr={"20px"}
          float={"right"}
          onClick={() => {
            navigate("/Stake-holder/CreateStakeholder");
          }}
          // w={"30vh"}
          leftIcon={<AddIcon />}
          bg={"#B76A1D"}
          color={"white"}
          variant="solid"
        >
          Create Stakeholder
        </Button>

        <Button
          mt={"20px"}
          mb={"20px"}
          mr={"20px"}
          float={"right"}
          onClick={() => {
            navigate("/Stake-holder/Createplowback");
          }}
          // w={"30vh"}
          leftIcon={<AddIcon />}
          bg={"#B76A1D"}
          color={"white"}
          variant="solid"
        >
          Create Activity
        </Button>
      </Box>
      <Center>
        <SatckholdersTable></SatckholdersTable>
      </Center>
    </Stack>
  );
}
