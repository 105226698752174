import {
  Heading,
  Checkbox,
  VStack,
  Text,
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { useQuery } from "react-query";
import axios from "axios";
import { colourStyles } from "../Voting/NomineeVoting";
import Select from "react-select";
import { Field, Formik } from "formik";
import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  phone: Yup.string()
    .required()
    .matches(phoneRegExp, "Phone number is not valid"),
});
export const CreateProxy = () => {
  const [isShareHolder, setIsShareHolder] = React.useState(false);
  const { token } = useAuth();
  const [data, setData] = useState([]);
  const [proxy, setProxy] = useState({
    name: "",
    phone: "",
    isShareHolder: isShareHolder,
  });

  const handleChange = (e) => {
    setProxy({
      // id: e.name,
      // phone: e.phone,
      id: e.id,
      isShareHolder,
    });
  };

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const getStakeHolder = useQuery(
    "getStakeHolder",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}stakeholders`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setData(res.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  return (
    <VStack>
      <Heading>Proxy Management </Heading>

      <Flex
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          my: "2rem",
        }}
      >
        <Text
          sx={{
            fontSize: "2xl",
            fontWeight: "bold",
            color: "gray.900",
            my: "2rem",
          }}
        >
          {" "}
          Create Proxy
        </Text>
        <Checkbox
          sx={{
            color: "gray.900",
            fontSize: "1.5rem",
            fontWeight: "bold",
            padding: "2rem",
            borderColor: "green.500",

            _focus: {
              boxShadow: "none",
            },
          }}
          onChange={() => setIsShareHolder(!isShareHolder)}
          value={isShareHolder}
          name="radio"
          colorScheme="green"
          my={".5rem"}
        >
          Is ShareHolder
        </Checkbox>
        {isShareHolder ? (
          <VStack>
            <Select
              w={"20vh"}
              styles={colourStyles}
              placeholder={"Select Proxy"}
              isDisabled={
                getStakeHolder.isError ||
                getStakeHolder.getStakeHolder?.isLoading
              }
              isLoading={getStakeHolder?.isLoading}
              options={data}
              value={data?.id}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.id}
              name="proxy"
              // isMulti
              onChange={handleChange}
            />

            <Button
              // style button to be disabled if no proxy is selected
              colorScheme="purple"
              width="full"
              my={"12"}
              onClick={() => console.log("to be made proxy", proxy)}
            >
              {" "}
              Make Proxy
            </Button>
          </VStack>
        ) : (
          <Formik
            initialValues={{
              name: "",
              phone: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                setProxy({ ...values, isShareHolder });
                console.log("proxy =>", proxy);
                actions.setSubmitting(false);
                actions.resetForm();
              }, 1000);
            }}
          >
            {(props) => (
              <VStack
                as={"form"}
                spacing={4}
                align="flex-start"
                onSubmit={props.handleSubmit}
              >
                <FormControl
                  isInvalid={!!props.errors.name && props.touched.name}
                >
                  <FormLabel htmlFor="name">Full Name</FormLabel>
                  <Field
                    p={6}
                    as={Input}
                    placeholder="Enter  Enter full name"
                    id="name"
                    name="name"
                    variant="filled"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.name}
                  />
                  <FormErrorMessage> {props.errors.name} </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!props.errors.phone && props.touched.phone}
                >
                  <FormLabel htmlFor="phone">Phone </FormLabel>
                  <Field
                    p={8}
                    as={Input}
                    placeholder="Enter phone number"
                    id="phone"
                    name="phone"
                    variant="filled"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.phone}
                  />
                  <FormErrorMessage>{props.errors.phone}</FormErrorMessage>
                </FormControl>

                <Button
                  type="submit"
                  disabled={!!props.errors.name || !!props.errors.phone}
                  colorScheme="purple"
                  width="full"
                >
                  Create Proxy
                </Button>
              </VStack>
            )}
          </Formik>
        )}
      </Flex>
    </VStack>
  );
};
