import logo from "./logo.svg";
import "./App.css";
import { useAuth } from "./utilities/hooks/auth-hook";
import StoreManagerRoutes from "./routes/StoreManagerRoutes";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Login from "./Login/Login";
import Error from "./storemanager/components/404";
import axios from "axios";
import React from "react";
import { useToast } from "@chakra-ui/react";

function App() {
  const { token } = useAuth();
  // let routes;
  // if (token === "null") {
  //   return <StoreManagerRoutes />;
  // }
  const { logout } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (token !== null) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}stakeholders`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .catch((err) => {
          if (err.response.status === 401) {
            logout();
            navigate("/login");
            toast({
              title: "Session expired",
              description: "Please log in again",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        });
    }
  }, [token]);

  return (
    <div className="App">
      {token === null ? (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Error />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      ) : (
        <StoreManagerRoutes />
      )}
    </div>
  );
}

export default App;
