import React from "react";
import { purple } from "@mui/material/colors";
import { Box, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Loader from "../../assets/Loader";
import { Button } from "@chakra-ui/react";

export default function Error() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "#f17cb3",
      }}
    >
      <Loader />
      {/* <Typography variant="h1" style={{ color: "white" }}>
        404
      </Typography> */}
      <Typography variant="h6" style={{ color: "white" }}>
        The page you’re looking for doesn’t exist.
      </Typography>
      <Button
        variant="solid"
        colorScheme="purple"
        size="md"
        height="50px"
        borderRadius="10px"
        fontSize="1rem"
        fontWeight="bold"
        lineHeight="1.5rem"
        letterSpacing="0.05rem"
        textTransform="uppercase"
        backgroundColor="purple.300"
        color="white"
        sx={{
          marginTop: 5,
        }}
        onClick={() => {
          navigate("/");
        }}
      >
        Back Home
      </Button>
    </Box>
  );
}
