import axios from "axios";
import React, { useState } from "react";
import {
  useToast,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
  Center,
  Text,
  Box,
  HStack,
  Td,
  InputGroup,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";

import { useAuth } from "../../utilities/hooks/auth-hook";
import ReportTable from "./Table/ReportTable";
import { useQuery } from "react-query";
import RTable from "./Table/RTable";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  PhoneIcon,
  SearchIcon,
} from "@chakra-ui/icons";

export default function Report() {
  const { token } = useAuth();
  const [profitedate, setprofitedate] = React.useState(new Date());
  const [selectedIndex, setselectedIndex] = useState(0);
  const [selectedT, setselectedT] = useState(0);
  const [selectedC, setselectedC] = useState(0);
  const [Data, setData] = React.useState(null);
  const [dataD, setDataD] = useState([]);
  const [Alldata, setAlldata] = useState([]);
  const [query, setquery] = useState("");

  const [dataT, setDataT] = useState([]);
  const [dataS, setDataS] = useState([]);
  const [dataP, setDataP] = useState([]);

  const [columnsP, setColumnsP] = useState([
    {
      title: "Date ",
      field: "created_at",
    },
    { title: "Name", field: "stakeholder.name" },

    {
      title: "Payment Type ",
      field: "type",
    },
    {
      title: "Amount ",
      field: "amount",
    },
    {
      title: "Invoice Number ",
      field: "invoice_number",
    },
  ]);
  function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
      React.useContext(VisibilityContext);

    return (
      <ArrowLeftIcon disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
        Left
      </ArrowLeftIcon>
    );
  }

  function RightArrow() {
    const { isLastItemVisible, scrollNext } =
      React.useContext(VisibilityContext);

    return (
      <ArrowRightIcon disabled={isLastItemVisible} onClick={() => scrollNext()}>
        Right
      </ArrowRightIcon>
    );
  }
  const [columnsD, setColumnsD] = useState([
    { title: "Name", field: "stakeholder.name" },
    {
      title: "Payable ",
      field: "payable",
    },
    {
      title: "Total Payable ",
      field: "total_payable",
    },
  ]);
  const [columnsT, setColumnsT] = useState([
    {
      title: "Date ",
      field: "created_at",
    },
    { title: "From", field: "from.name" },
    {
      title: "To",
      field: "to.name",
    },
    {
      title: "Amount ",
      field: "amount",
    },
  ]);
  const [columnsS, setColumnsS] = useState([
    { title: "Name", field: "stakeholder.name" },
    {
      title: "Number of shares ",
      field: "no_of_shares",
    },
    {
      title: "Amount ",
      field: "amount",
    },
  ]);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const getCaptitalG = useQuery(
    "getCaptitalG",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}capital-growths`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        var id = res.data.data[0].model;
        setData(id);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  console.log({ getCaptitalG });
  const getReportTable = useQuery(
    "getReportTable",
    async () =>
      await axios.get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }stakeholder-report?model=${Data}&budjetYear=${
          profitedate.getFullYear() - 9
        }`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!Data,
      onSuccess: (res) => {
        console.log(res.data.data);
        setAlldata(res.data.data.stakeholders);
        var ind = 0;
        var dataAdp = 0;
        var Transferdata = 0;
        var cashout = 0;
        for (var i = 0; i < res.data.data.stakeholders.length; i++) {
          if (dataAdp <= res.data.data.stakeholders[i].payments.length) {
            dataAdp = res.data.data.stakeholders[i].payments.length;
            setselectedIndex(i);
            console.log(i);
          }
          if (Transferdata < res.data.data.stakeholders[i].transfers.length) {
            Transferdata = res.data.data.stakeholders[i].transfers.length;
            setselectedT(i);
          }
          if (cashout < res.data.data.stakeholders[i].cashout.length) {
            cashout = res.data.data.stakeholders[i].cashout.length;
            setselectedC(i);
          }
        }
        // for (
        //   var i = 0;
        //   i < res.data.data.stakeholders[ind].payments.length;
        //   i++
        // ) {
        //   data = [...data, (data = res.data.data.stakeholders[ind].payments)];
        // }

        // setDataD(res.data.data.dividends);
        // setDataP(res.data.data.payments);
        // setDataS(res.data.data.shares);
        // setDataT(res.data.data.transfer);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  console.log({ getReportTable });
  console.log({ selectedIndex });

  return (
    <Box padding={3}>
      <InputGroup maxW={"50vh"} mb={5}>
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon color="gray.300" />}
        />
        <Input
          type="tel"
          placeholder="Search by Name"
          onChange={(e) => setquery(e.target.value)}
        />
      </InputGroup>

      {getCaptitalG.isFetching ? (
        <Text>Laoding....</Text>
      ) : !!Data ? (
        getReportTable.isFetching ? (
          <Text>Laoding....</Text>
        ) : (
          <div>
            {/* <Text>{Alldata[selectedIndex]?.payments.length}</Text> */}
            {/* <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}> */}
            <Box
              border={"1px solid rgba(0, 0, 0, 0.05)"}
              maxW={"100%"}
              overflowX={"scroll"}
              style={{
                overflowY: "scroll",
                height: "82vh",
              }}
            >
              {" "}
              <table
                style={{
                  border: "1px solid ",

                  textAlign: "center",
                }}
              >
                <thead
                  style={{
                    position: "sticky",
                    top: "0",
                    border: "2px solid ",

                    background: "white",
                  }}
                >
                  <tr>
                    <th
                      style={{
                        border: "1px solid ",
                      }}
                    >
                      NO
                    </th>
                    <th
                      style={{
                        border: "1px solid ",
                      }}
                    >
                      Name of Shareholders
                    </th>
                    <th
                      style={{
                        border: "1px solid ",
                      }}
                    >
                      Payable
                    </th>
                    <th
                      style={{
                        border: "1px solid ",
                      }}
                    >
                      Dividend
                    </th>
                    <th
                      style={{
                        border: "1px solid ",
                      }}
                    >
                      Paid up Capital
                    </th>
                    {Alldata[0]?.capital_growth.map((item) => {
                      return (
                        <th
                          style={{
                            border: "1px solid ",
                          }}
                        >
                          {" "}
                          <Text>{item.share_growth_persont}%</Text>{" "}
                        </th>
                      );
                    })}{" "}
                    <th
                      style={{
                        border: "1px solid ",
                      }}
                    >
                      Request Share
                    </th>
                    <th
                      style={{
                        border: "1px solid ",
                      }}
                    >
                      Plowback
                    </th>
                    {Alldata[selectedIndex]?.payments?.map((item) => {
                      return (
                        <>
                          <th
                            style={{
                              border: "1px solid ",
                            }}
                          >
                            <Center>Additional Payment </Center>
                            <Box height={0.3} w={"100%"} bg={"black"}></Box>
                            <HStack>
                              <td>
                                {" "}
                                <Text w={20}>Amount</Text>{" "}
                              </td>
                              <Box height={10} w={0.5} bg={"black"}></Box>
                              <td>
                                {" "}
                                <Text w={20}>Date</Text>{" "}
                              </td>
                              <Box height={10} w={0.5} bg={"black"}></Box>
                              <td>
                                {" "}
                                <Text w={20}>ACRV</Text>{" "}
                              </td>
                            </HStack>
                          </th>
                        </>
                      );
                    })}
                    {Alldata[selectedT]?.transfers?.map((item) => {
                      return (
                        <>
                          <th
                            style={{
                              border: "1px solid ",
                            }}
                          >
                            <Center>Transfer </Center>
                            <Box height={0.3} w={"100%"} bg={"black"}></Box>
                            <HStack>
                              <td>
                                {" "}
                                <Text w={20}>Amount</Text>{" "}
                              </td>
                              <Box height={10} w={0.5} bg={"black"}></Box>
                              <td>
                                {" "}
                                <Text w={20}>to</Text>{" "}
                              </td>
                              <Box height={10} w={0.5} bg={"black"}></Box>
                              <td>
                                {" "}
                                <Text w={20}>Date</Text>{" "}
                              </td>
                            </HStack>
                          </th>
                        </>
                      );
                    })}
                    {Alldata[selectedC]?.cashout?.map((item) => {
                      return (
                        <>
                          <th
                            style={{
                              border: "1px solid ",
                            }}
                          >
                            <Center>ACPV </Center>
                            <Box height={0.3} w={"100%"} bg={"black"}></Box>
                            <HStack>
                              <td>
                                {" "}
                                <Text w={20}>Payment</Text>{" "}
                              </td>
                              <Box height={10} w={0.5} bg={"black"}></Box>
                              <td>
                                {" "}
                                <Text w={20}>10%</Text>{" "}
                              </td>
                              <Box height={10} w={0.5} bg={"black"}></Box>
                              <td>
                                {" "}
                                <Text w={20}>ACPV</Text>{" "}
                              </td>
                              <Box height={10} w={0.5} bg={"black"}></Box>
                              <td>
                                {" "}
                                <Text w={20}>Date</Text>{" "}
                              </td>
                            </HStack>
                          </th>
                        </>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {Alldata?.filter((item) => item.name.includes(query)).map(
                    (item, index) => {
                      return (
                        <tr
                          key={index}
                          style={{
                            textAlign: "center",
                            border: "1px solid ",
                          }}
                        >
                          <td
                            style={{
                              border: "1px solid ",
                              // position: "sticky",
                              // left: "0",
                              // background: "white",
                            }}
                          >
                            {index + 1}
                          </td>
                          <td
                            style={{
                              border: "1px solid ",
                              // position: "sticky",
                              // left: "0",
                              // background: "white",
                            }}
                          >
                            {item?.name}
                          </td>
                          <td
                            style={{
                              border: "1px solid ",
                            }}
                          >
                            {item?.payable}
                          </td>
                          <td
                            style={{
                              border: "1px solid ",
                            }}
                          >
                            {item?.dividend}{" "}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid ",
                            }}
                          >
                            {item?.current_capital}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid ",
                            }}
                          >
                            {item?.plowback}
                          </td>
                          {item?.capital_growth.map((data) => {
                            return (
                              <td
                                style={{
                                  border: "1px solid ",
                                }}
                              >
                                {" "}
                                <Text w={20}>{data.share_growth}</Text>{" "}
                              </td>
                            );
                          })}
                          <td
                            style={{
                              textAlign: "center",
                              border: "1px solid ",
                            }}
                          >
                            {item?.request_share_amount}
                          </td>
                          {item?.payments.length !== 0 ? (
                            <>
                              {item?.payments.map((data) => {
                                return (
                                  <>
                                    <td
                                      style={{
                                        border: "1px solid ",
                                      }}
                                    >
                                      <HStack>
                                        <td>
                                          {" "}
                                          <Text w={20}>
                                            {data.money_amount}
                                          </Text>{" "}
                                        </td>
                                        <Box
                                          height={12}
                                          w={0.5}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>
                                            {data.additional_payment_date}
                                          </Text>{" "}
                                        </td>
                                        <Box
                                          height={12}
                                          w={0.5}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>
                                            {data.acrv_no}
                                          </Text>{" "}
                                        </td>
                                      </HStack>
                                    </td>
                                  </>
                                );
                              })}
                              {[
                                ...Array(
                                  Alldata[selectedIndex]?.payments?.length -
                                    item?.payments.length
                                ).keys(),
                              ].map((item) => {
                                return (
                                  <>
                                    <td
                                      style={{
                                        border: "1px solid ",
                                      }}
                                    >
                                      <HStack>
                                        <td>
                                          {" "}
                                          <Text w={20}>-</Text>{" "}
                                        </td>
                                        <Box
                                          height={10}
                                          w={0.3}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>-</Text>{" "}
                                        </td>
                                        <Box
                                          height={10}
                                          w={0.3}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>-</Text>{" "}
                                        </td>
                                      </HStack>
                                    </td>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            Alldata[selectedIndex]?.payments?.map((item) => {
                              return (
                                <>
                                  <td
                                    style={{
                                      border: "1px solid ",
                                    }}
                                  >
                                    <HStack>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                      <Box
                                        height={10}
                                        w={0.5}
                                        bg={"black"}
                                      ></Box>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                      <Box
                                        height={10}
                                        w={0.5}
                                        bg={"black"}
                                      ></Box>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                    </HStack>
                                  </td>
                                </>
                              );
                            })
                          )}

                          {item?.transfers.length !== 0 ? (
                            <>
                              {item?.transfers.map((data) => {
                                return (
                                  <>
                                    <td
                                      style={{
                                        border: "1px solid ",
                                      }}
                                    >
                                      <HStack>
                                        <td>
                                          {" "}
                                          <Text w={20}>
                                            {data.money_amount}
                                          </Text>{" "}
                                        </td>
                                        <Box
                                          height={12}
                                          w={0.5}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          <Text w={20}>{data.to.name}</Text>{" "}
                                        </td>
                                        <Box
                                          height={12}
                                          w={0.5}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>
                                            {data.transfer_date}
                                          </Text>{" "}
                                        </td>
                                      </HStack>
                                    </td>
                                  </>
                                );
                              })}
                              {[
                                ...Array(
                                  Alldata[selectedT]?.transfers?.length -
                                    item?.transfers.length
                                ).keys(),
                              ].map((item) => {
                                return (
                                  <>
                                    <td
                                      style={{
                                        border: "1px solid ",
                                      }}
                                    >
                                      <HStack>
                                        <td>
                                          {" "}
                                          <Text w={20}>-</Text>{" "}
                                        </td>
                                        <Box
                                          height={10}
                                          w={0.3}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>-</Text>{" "}
                                        </td>
                                        <Box
                                          height={10}
                                          w={0.3}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>-</Text>{" "}
                                        </td>
                                      </HStack>
                                    </td>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            Alldata[selectedT]?.transfers?.map((item) => {
                              return (
                                <>
                                  <td
                                    style={{
                                      border: "1px solid ",
                                    }}
                                  >
                                    <HStack>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                      <Box
                                        height={10}
                                        w={0.5}
                                        bg={"black"}
                                      ></Box>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                      <Box
                                        height={10}
                                        w={0.5}
                                        bg={"black"}
                                      ></Box>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                    </HStack>
                                  </td>
                                </>
                              );
                            })
                          )}
                          {item?.cashout?.length !== 0 ? (
                            <>
                              {item?.cashout?.map((data) => {
                                console.log({ data });
                                return (
                                  <>
                                    <td
                                      style={{
                                        border: "1px solid ",
                                      }}
                                    >
                                      <HStack>
                                        <td>
                                          {" "}
                                          <Text w={20}>
                                            {data?.new_money_amount}
                                          </Text>{" "}
                                        </td>
                                        <Box
                                          height={12}
                                          w={0.5}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          <Text w={20}>{data?.tax}</Text>{" "}
                                        </td>
                                        <Box
                                          height={12}
                                          w={0.5}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          <Text w={20}>{data?.acpv_no}</Text>{" "}
                                        </td>
                                        <Box
                                          height={12}
                                          w={0.5}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>
                                            {data?.cashout_date}
                                          </Text>{" "}
                                        </td>
                                      </HStack>
                                    </td>
                                  </>
                                );
                              })}
                              {[
                                ...Array(
                                  Alldata[selectedC]?.cashout?.length -
                                    item?.cashout?.length
                                ).keys(),
                              ].map((item) => {
                                return (
                                  <>
                                    <td
                                      style={{
                                        border: "1px solid ",
                                      }}
                                    >
                                      <HStack>
                                        <td>
                                          {" "}
                                          <Text w={20}>-</Text>{" "}
                                        </td>
                                        <Box
                                          height={10}
                                          w={0.3}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>-</Text>{" "}
                                        </td>
                                        <Box
                                          height={10}
                                          w={0.3}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>-</Text>{" "}
                                        </td>
                                        <Box
                                          height={10}
                                          w={0.3}
                                          bg={"black"}
                                        ></Box>
                                        <td>
                                          {" "}
                                          <Text w={20}>-</Text>{" "}
                                        </td>
                                      </HStack>
                                    </td>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            Alldata[selectedC]?.cashout?.map((item) => {
                              return (
                                <>
                                  <td
                                    style={{
                                      border: "1px solid ",
                                    }}
                                  >
                                    <HStack>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                      <Box
                                        height={10}
                                        w={0.5}
                                        bg={"black"}
                                      ></Box>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                      <Box
                                        height={10}
                                        w={0.5}
                                        bg={"black"}
                                      ></Box>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                      <Box
                                        height={10}
                                        w={0.5}
                                        bg={"black"}
                                      ></Box>
                                      <td>
                                        {" "}
                                        <Text w={20}>-</Text>{" "}
                                      </td>
                                    </HStack>
                                  </td>
                                </>
                              );
                            })
                          )}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </Box>
            {/* </ScrollMenu> */}
          </div>
        )
      ) : (
        <Text>Create Share growths first</Text>
      )}
      {/* <Tabs>
        <TabList>
          <Tab>Dividends</Tab>
          <Tab>Payments</Tab>
          <Tab>Shares</Tab>
          <Tab>Transfers</Tab>
          <Tab>text</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <ReportTable column={columnsD} row={dataD} title="Dividends List" />
          </TabPanel>
          <TabPanel>
            <ReportTable column={columnsP} row={dataP} title="Payments List" />
          </TabPanel>
          <TabPanel>
            <ReportTable column={columnsS} row={dataS} title="Shares List" />
          </TabPanel>
          <TabPanel>
            <ReportTable column={columnsT} row={dataT} title="Transfers List" />
          </TabPanel>

          <TabPanel>
            <div>
              <table
                className={{
                  fontFamily: "arial, sans-serif",
                  borderCollapse: "collapse",
                  width: "100%",
                }}
              >
                <tr
                  className={{
                    border: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  <th
                    className={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                    This{" "}
                  </th>
                  <th
                    className={{
                      border: "1px solid #dddddd",
                      textAlign: "left",
                      padding: "8px",
                    }}
                  >
                    Next{" "}
                  </th>
                </tr>
                <tr>
                  <td>This is where the table data goes</td>
                  <td>This is the second columns data</td>
                </tr>
              </table>
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs> */}

      {/* <input type="file" id="input" onChange={onChange} />
      <Button onClick={ShareMutationHandler}>Share</Button> */}
    </Box>
  );
}
