import React, { useEffect } from "react";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { useQuery } from "react-query";
import { Box, Progress, Text } from "@chakra-ui/react";
import MaterialTable from "material-table";
import axios from "axios";
export const AttendantTable = ({ meetingId, refetch }) => {
  const { token } = useAuth();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const getAttendant = useQuery(
    ["attended", "meetingId"],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}attended/${meetingId}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );
  useEffect(() => {
    if (refetch) getAttendant.refetch();
  }, [refetch]);

  return (
    <Box w={"100%"}>
      <MaterialTable
        isLoading={getAttendant.isLoading}
        style={{
          zIndex: "0",
          margin: "3rem",
          boxShadow: "0 0 10px rgba(0,0,0,0.2)",
        }}
        components={{
          OverlayLoading: (props) => (
            <Progress size="md" colorScheme="pink" isIndeterminate />
          ),
        }}
        title="Attendant List"
        columns={[
          { title: "Name", field: "attendable.name" },
          { title: "Phone Number", field: "attendable.phone" },
          {
            title: "Share",
            field: "shares",
            render: (rowData) => {
              return (
                <Text>
                  {Number(
                    Math.round(rowData.shares * 10) / 10
                  ).toLocaleString()}
                </Text>
              );
            },
          },
          {
            title: "Shares",
            field: "type",

            render: (rowData) => {
              return (
                <Text
                  sx={{
                    fontWeight: "bolder",
                  }}
                >
                  {rowData.type}
                </Text>
              );
            },
          },
        ]}
        data={getAttendant?.data?.data?.data}
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: "#E82481",
            color: "#FFF",
          },
        }}
      />
    </Box>
  );
};
