import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Button,
} from "@chakra-ui/react";
import MaterialTable from "material-table";
import React from "react";
// import { useQuery } from "react-query";
import { BsThreeDots } from "react-icons/bs";
import { BiShowAlt } from "react-icons/bi";
import { RiEdit2Line, RiDeleteBin6Line } from "react-icons/ri";
import Loader from "../../../../assets/Loader";
import { useAuth } from "../../../../utilities/hooks/auth-hook";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
export default function FreeShareTable(props) {
  const { data } = props;
  const { useState } = React;

  const [columns, setColumns] = useState([
    { title: "Name", field: "name" },
    {
      title: "Total Capital ",
      field: "current_capital",
    },

    {
      title: "available Share",
      field: "CurrentYearAmount",
    },

    // {
    //   title: "Action",
    //   render: (rowData) => {
    //     return (
    //       <Button
    //         onClick={() =>
    //           navigate("/Share_request/Create_Share_request", {
    //             state: {
    //               state: rowData,
    //               isReadOnly: true,
    //               isEdit: false,
    //               isShow: true,
    //             },
    //           })
    //         }
    //         bg={"#B76A1D"}
    //         color={"white"}
    //       >
    //         Detail
    //       </Button>
    //     );
    //   },
    // },
  ]);

  return (
    <Box w={"100%"}>
      <MaterialTable
        title="Cashout users"
        columns={columns}
        data={data?.cashOuts}
        options={{
          filtering: true,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: "#E82481",
            color: "#FFF",
          },
        }}
      />
      <MaterialTable
        title="Unavailable Users"
        columns={columns}
        data={data?.monthPassed}
        options={{
          filtering: true,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: "#E82481",
            color: "#FFF",
          },
        }}
      />
    </Box>
  );
}
