import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Stack,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import FormComp from "../../utilities/components/FormComp";
import * as Yup from "yup";
import Select from "react-select";

import { useAuth } from "../../utilities/hooks/auth-hook";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export default function Createprofit() {
  const {
    state: { state, isReadOnly, isEdit, isShow },
  } = useLocation();
  const [Start_Year, setStart_Year] = React.useState();
  const [End_Year, setEnd_Year] = React.useState();
  const [selectedOption, setselectedOption] = React.useState([]);
  const [options, setoptions] = React.useState([]);
  const handleChange = (selectedOption) => {
    setselectedOption(selectedOption);
  };
  const [selectedDate, handleDateChange] = React.useState(new Date());
  const [selectedDate2, handleDateChange2] = React.useState(new Date());

  useEffect(() => {
    let newDate = new Date();

    let year = newDate.getFullYear();
    setStart_Year(`${year}-07-01`);
    setEnd_Year(`${year + 1}-06-30`);
  }, []);

  console.log({ selectedDate2 });
  const navigate = useNavigate();
  const { token } = useAuth();
  const toast = useToast();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const toSqlDatetime = (inputDate) => {
    var dd = String(inputDate.getDate()).padStart(2, "0");
    var mm = String(inputDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = inputDate.getFullYear();
    inputDate = yyyy;
    return inputDate;
  };
  const profitMutation = useMutation(
    async (newData) =>
      isEdit
        ? await axios.patch(
            process.env.REACT_APP_BACKEND_URL + "profits" + "/" + state.id,
            newData,
            {
              headers,
            }
          )
        : await axios.post(
            process.env.REACT_APP_BACKEND_URL + "profits",
            newData,
            {
              headers,
            }
          ),
    {
      retry: false,
    }
  );
  const ProfitMutationHandler = async (values) => {
    const { start_date, end_date, amount } = values;

    console.log({ selectedOption });
    try {
      profitMutation.mutate(
        {
          start_date: isEdit ? Start_Year : Start_Year,
          end_date: isEdit ? End_Year : End_Year,
          budget_year: selectedDate.getFullYear() - 8,
          amount: amount,
        },
        {
          onSuccess: (responseData) => {
            toast({
              title: isEdit
                ? "Profite  Edited successfully"
                : "Profite  created successfully",
              status: "success",
              duration: 2500,
              isClosable: true,
            });
            navigate("/dividend");
          },
          onError: (err) => {
            console.log(err);
            toast({
              title: isEdit ? "Profite not edited." : "Profite  not created.",
              status: "error",
              duration: 2500,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {
      console.log(err);
      toast({
        title: "Error when Creating",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };
  console.log({ state });
  return (
    <Stack
      bg={"white"}
      boxShadow={"base"}
      w={"100%"}
      p={8}
      spacing={6}
      justify={"center"}
      align={"center"}
    >
      <Box w={"80%"}>
        <Heading mb={"40px"}> Profit</Heading>
        <Formik
          initialValues={{
            amount: state !== null ? state.money_amount : "",
          }}
          validationSchema={Yup.object({
            // nameid: Yup.string().required("Required !"),

            amount: Yup.string().required("Required !"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            ProfitMutationHandler(values);
            console.log({ values });
            setSubmitting(false);
          }}
        >
          {(formik) => {
            console.log({ formik });
            // console.log(toSqlDatetime(formik.values.start_date));
            return (
              <form onSubmit={formik.handleSubmit}>
                <HStack spacing={8}>
                  <Box align={"left"} justifyContent={"left"}>
                    <Text textAlign={"left"} mb={1} fontWeight={"bold"}>
                      {" "}
                      Profit Year
                    </Text>
                    <Box borderRadius={5} p={3} px={8} bgColor={"#e5e7eb"}>
                      <Center>
                        {Start_Year} to {End_Year}
                      </Center>
                    </Box>
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        clearable
                        selectsRange={true}
                        value={formik.values.start_date}
                        id={`start_date`}
                        placeholder="End Date"
                        views={["year"]}
                        onChange={(value) => {
                          // console.log();
                          formik.setFieldValue(`start_date`, value);
                        }}
                        // onChange={(date) => handleDateChange(date)}
                        // minDate={new Date()}
                        format="yyyy"
                      />
                    </MuiPickersUtilsProvider> */}
                  </Box>
                  {/* <Box align={"left"} justifyContent={"left"}>
                    <Text textAlign={"left"}> Select End Date</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        clearable
                        selectsRange={true}
                        id={"end_date"}
                        value={formik.values.end_date}
                        placeholder="Select end_date"
                        onChange={(value) => {
                          // console.log();
                          formik.setFieldValue("end_date", value);
                        }}
                        // onChange={(date) => handleDateChange2(date)}
                        // minDate={new Date()}
                        format="yyyy"
                      />
                    </MuiPickersUtilsProvider>
                  </Box> */}
                </HStack>
                <Box mt={4}>
                  <FormComp
                    id={"amount"}
                    label={"Profit Amount(birr)"}
                    formik={formik}
                    isReadOnly={isReadOnly}
                  />
                </Box>

                {!isShow && (
                  <Button
                    disabled={formik.isSubmitting}
                    mt={6}
                    type="submit"
                    bg={"#B76A1D"}
                    color={"white"}
                    variant="solid"
                    isReadOnly={isReadOnly}
                  >
                    Submit
                  </Button>
                )}
              </form>
            );
          }}
        </Formik>
      </Box>
    </Stack>
  );
}
