import {
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import CrosssiteActivity from "./components/CrosssiteActivity";
// import DashboardMain from "./components/DashboardMain";
// import LineGraph from "./components/LineGraph";
import OutdoorSales from "./components/OutdoorSales";
import RevenueComposition from "./components/RevenueComposition";
import { useAuth } from "../../utilities/hooks/auth-hook";

export default function DashBoard() {
  //Hook
  const { token } = useAuth();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const dashboardData = useQuery(
    `dashboardCardData`,
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}dashboard`, {
        headers,
      }),
    {
      keepPreviousData: true,
      retry: true,
      enabled: !!token,
    }
  );

  // console.log({ dashboardData: dashboardData?.data?.data?.data?.overview });

  //Function
  function Cards({ title, subTitle, body, color }) {
    return (
      <Stack
        justifyContent={"flex-start"}
        bg={"white"}
        color={"#31C736"}
        w={"250px"}
        h={"125px"}
        px={6}
        py={4}
        mr={4}
        boxShadow={"md"}
        rounded={"md"}
      >
        {/* <Heading size={'lg'} fontWeight={'semibold'}>
          {title}
        </Heading> */}
        {/* <HStack alignItems={'flex-end'}> */}
        <Heading size={"2xl"} color={color}>
          {title}
        </Heading>
        <dr></dr>
        <Heading size={"xs"} color={"black"}>
          {subTitle}
        </Heading>
        {/* </HStack> */}
      </Stack>
    );
  }

  //Jsx
  return (
    <Stack padding={4}>
      {/* <SimpleGrid columns={3} spacing={8}> */}
      <Wrap justify={["center", "flex-start"]}>
        <WrapItem>
          <Cards
            title={dashboardData?.data?.data?.data?.stakeholders}
            subTitle="Total Shareholders"
            color={"#E82481"}
            // body={dashboardData?.data?.data?.data?.overview?.activeOrders}
          />
        </WrapItem>
        <WrapItem>
          <Cards
            title={dashboardData?.data?.data?.data?.totalRevenue}
            subTitle="Total Revenue This Year"
            color={"#18B933"}
            // body={dashboardData?.data?.data?.data?.overview?.todaySchedules}
          />
        </WrapItem>
        <WrapItem>
          <Cards
            title={dashboardData?.data?.data?.data?.totalDividend}
            subTitle="Total Dividend This Year"
            color={"#FAD03C"}
            // body={dashboardData?.data?.data?.data?.overview?.todaySold}
          />
        </WrapItem>
      </Wrap>

      {/* <Cards
          title="Currently"
          subTitle="No of items in critical level"
          // body={dashboardData?.data?.data?.data?.overview?.criticalItem}
        /> */}
      {/* <Cards
          title="Currently"
          subTitle="No of total customers"
          // body={dashboardData?.data?.data?.data?.overview?.totalCustomers}
        /> */}
      {/* <Cards
          title="Currently"
          subTitle="No. of Total Items in Stock"
          // body={dashboardData?.data?.data?.data?.overview?.totalStock}
        />
        <Cards
          title="Currently"
          subTitle="No. of Total Suppliers"
          // body={dashboardData?.data?.data?.data?.overview?.totalSuppliers}
        />
        <Cards
          title="Currently"
          subTitle="No. of Total Drivers"
          // body={dashboardData?.data?.data?.data?.overview?.totalDrivers}
        /> */}
      {/* </SimpleGrid> */}
      {/* <LineGraph /> */}
      <Stack
        pt={8}
        spacing={"35px"}
        // w={{ base: "71%", xl: "95%" }}
      >
        {/* <DashboardMain /> */}
        <CrosssiteActivity />
        <RevenueComposition />
        {/* <OutdoorSales /> */}
      </Stack>
    </Stack>
  );
}
