import {
  MdDashboard,
  MdHowToVote,
  MdPointOfSale,
  MdViewAgenda,
} from "react-icons/md";
import { TbReportMoney } from "react-icons/tb";
import { GiBuyCard, GiReceiveMoney, GiProfit } from "react-icons/gi";
import { BiTransfer } from "react-icons/bi";
import { FiUserPlus } from "react-icons/fi";
import { AiFillExclamationCircle } from "react-icons/ai";
import { RiGitPullRequestLine } from "react-icons/ri";
import { BsFillCapslockFill } from "react-icons/bs";
import { FaHistory } from "react-icons/fa";

const Sss = GiBuyCard;

export const StoreManagerRoutes = [
  { name: "Dashboard", to: "/dashboard", icon: <MdDashboard size={"1.7em"} /> },

  {
    name: "Stakeholders",
    to: "/Stake-holder",
    icon: <FiUserPlus size={"1.7em"} />,
  },

  {
    name: "Dividend, Average & Profit",
    to: "/dividend",
    icon: <TbReportMoney size={"1.7em"} />,
  },
  {
    name: "Share Request",
    to: "/Share_request",
    icon: <RiGitPullRequestLine size={"1.7em"} />,
  },
  {
    name: "Capital Growth",
    to: "/Capital-growth",
    icon: <BsFillCapslockFill size={"1.7em"} />,
  },
  {
    name: "Transfers ",
    to: "/transfers",
    icon: <BiTransfer size={"1.7em"} />,
  },
  {
    name: "Payments",
    to: "/payments",
    icon: <GiReceiveMoney size={"1.7em"} />,
  },

  // {
  //   name: "Profit",
  //   to: "/profit",
  //   icon: <GiProfit size={"1.7em"} />,
  // },
  {
    name: "Report ",
    to: "/report",
    icon: <AiFillExclamationCircle size={"1.7em"} />,
  },
  {
    name: "Upload History  ",
    to: "/history",
    icon: <FaHistory size={"1.7em"} />,
  },
  {
    name: "Meeting Management",

    to: "/meeting",
    icon: <MdPointOfSale size={"1.7em"} />,
  },
  {
    name: "Nominee Voting",
    to: "/voting",
    icon: <MdHowToVote size={"1.7em"} />,
  },
  {
    name: "Agenda Voting",
    to: "/agenda-voting",
    icon: <MdViewAgenda size={"1.7em"} />,
  },
  {
    name: "Proxy Management",
    to: "/proxy",
    icon: <Sss size={"1.7em"} />,
  },
];
