import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Stack,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import * as Yup from "yup";
import Select from "react-select";

import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";
import { useAuth } from "../../../utilities/hooks/auth-hook";
import FormComp from "../../../utilities/components/FormComp";
export default function CreateSharerequest() {
  const [selectedOption, setselectedOption] = React.useState([]);
  const [options, setoptions] = React.useState([]);
  const handleChange = (selectedOption) => {
    setselectedOption(selectedOption);
  };
  const {
    state: { state, isReadOnly, isEdit, isShow },
  } = useLocation();
  const navigate = useNavigate();
  const { token } = useAuth();
  const toast = useToast();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const PaymentMutation = useMutation(
    async (newData) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "request-share",
        newData,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );
  const PaymentMutationHandler = async (values) => {
    const { amount } = values;
    console.log({ selectedOption });
    try {
      PaymentMutation.mutate(
        {
          stakeholder_id: selectedOption.id,
          share_amount: amount,
        },
        {
          onSuccess: (responseData) => {
            toast({
              title: "payment created successfully",
              status: "success",
              duration: 2500,
              isClosable: true,
            });
            navigate("/Share_request");
          },
          onError: (err) => {
            console.log(err);
            toast({
              title: "payment not created.",
              status: "error",
              duration: 2500,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {
      console.log(err);
      toast({
        title: "Error when Creating",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };
  const getStakeHoldername = useQuery(
    "getStakeHoldername",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}stakeholders`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setoptions(res.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  console.log({ getStakeHoldername });
  console.log({ selectedOption });
  return (
    <Stack
      bg={"white"}
      boxShadow={"base"}
      w={"100%"}
      p={8}
      spacing={6}
      justify={"center"}
      align={"center"}
    >
      <Box w={"80%"}>
        <Heading mb={"40px"}> Share Request</Heading>
        <Formik
          initialValues={{
            name: state !== null ? state.stakeholder.name : "",
            amount: state !== null ? state.amount : "",
          }}
          validationSchema={Yup.object({
            // nameid: Yup.string().required("Required !"),
            amount: Yup.number().required("Required !"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            PaymentMutationHandler(values);
            console.log({ values });
            setSubmitting(false);
          }}
        >
          {(formik) => {
            console.log({ formik });
            return (
              <form onSubmit={formik.handleSubmit}>
                {isEdit ? (
                  <FormComp
                    id={"name"}
                    label={"Name"}
                    formik={formik}
                    isReadOnly={isReadOnly}
                  />
                ) : isShow ? (
                  <FormComp
                    id={"name"}
                    label={"Name"}
                    formik={formik}
                    isReadOnly={isReadOnly}
                  />
                ) : (
                  <FormControl py={2} textAlign={"left"}>
                    <FormLabel>Select Stakeholder</FormLabel>
                    <Select
                      styles={{ textAlign: "left" }}
                      w={"20vh"}
                      // id={"nameid"}
                      placeholder="select"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      value={selectedOption}
                      onChange={(newSelection) => {
                        console.log(newSelection.id);
                        // formik?.setFieldValue("nameid", newSelection.id);
                        handleChange(newSelection);
                      }}
                      options={options}
                    />
                  </FormControl>
                )}
                <FormComp
                  id={"amount"}
                  label={"Enter Share Amount"}
                  formik={formik}
                />

                {!isShow && (
                  <Button
                    disabled={formik.isSubmitting}
                    mt={6}
                    type="submit"
                    bg={"#B76A1D"}
                    color={"white"}
                    variant="solid"
                    isReadOnly={isReadOnly}
                  >
                    Submit
                  </Button>
                )}
              </form>
            );
          }}
        </Formik>
      </Box>
    </Stack>
  );
}
