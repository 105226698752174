import React, { useState } from "react";
import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { Input, InputGroup } from "@chakra-ui/input";

import {
  Center,
  Img,
  InputRightElement,
  Spinner,
  Text,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import * as Yup from "yup";
import axios from "axios";
import { useMutation } from "react-query";
import { Field, Form, Formik } from "formik";
import showPassIcon from "../../assets/password/show.png";
import hidePassIcon from "../../assets/password/hide.png";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { useNavigate } from "react-router-dom";

const PasswordChangeModal = ({ loginData }) => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);

  console.log({ loginData });

  const toast = useToast();
  const { login } = useAuth();
  const navigate = useNavigate();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${loginData?.data?.data?.token}`,
  };

  const passwordMutation = useMutation(
    async (updatePassword) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/change-password",
        updatePassword,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .min(8, "Too Short!")

      .max(35, "Too Long!")

      .required("Required"),
    newPassword: Yup.string()
      .min(8, "Too Short!")

      .max(35, "Too Long!")

      .required("Required"),
    confirmNewPassword: Yup.string()
      .required("Confirm password is a required field")
      .label("Confirm password")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.newPassword === value;
      }),
  });
  const onSubmit = async (values) => {
    const { oldPassword, newPassword, confirmNewPassword } = values;
    try {
      passwordMutation.mutate(
        {
          password: oldPassword,
          new_password: newPassword,
          new_password_confirmation: confirmNewPassword,
        },
        {
          onSuccess: (responseDataSucc) => {
            let user = {
              id: loginData?.data?.data?.id,
              email: loginData?.data?.data?.email,
              firstName: loginData?.data?.data?.first_name,
              lastName: loginData?.data?.data?.last_name,
              firstLogin: loginData?.data?.data?.first_login,
            };
            login(
              loginData?.data?.data?.role_name,
              loginData?.data?.data?.token,
              user
            );
            navigate("/");
            toast({
              title: "Updated Password successfully",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          },
          onError: (responseDataError) => {
            console.log({ responseDataError });
            toast({
              title:
                responseDataError?.response?.data?.data ||
                responseDataError?.response?.data?.message ||
                "Error occured during password update",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          },
        }
      );
      // Redirect the admin to a different page
    } catch (err) {}
  };

  const CustomInput = ({
    field,
    title,
    htmlFor,
    type,
    error,
    touched,
    extra,
    isEmail,
  }) => {
    return (
      <WrapItem
        my={4}
        w={
          htmlFor === "newPassword" ||
          htmlFor === "confirmNewPassword" ||
          htmlFor === "oldPassword"
            ? "18rem"
            : "16rem"
        }
      >
        <FormControl isInvalid={error && touched}>
          {/* <FormLabel htmlFor={htmlFor}>{title}</FormLabel> */}
          {extra === "password" && (
            <InputGroup size="md">
              {htmlFor === "oldPassword" && (
                <Input
                  {...field}
                  id={htmlFor}
                  placeholder={title}
                  type={showOldPassword ? "text" : "password"}
                />
              )}
              {htmlFor === "newPassword" && (
                <Input
                  {...field}
                  id={htmlFor}
                  placeholder={title}
                  type={showNewPassword ? "text" : "password"}
                />
              )}
              {htmlFor === "confirmNewPassword" && (
                <Input
                  {...field}
                  id={htmlFor}
                  placeholder={title}
                  type={showConfirmPassword ? "text" : "password"}
                />
              )}
              <InputRightElement width="4.5rem">
                {htmlFor === "oldPassword" && (
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                  >
                    <Img
                      w="25px"
                      src={showOldPassword ? showPassIcon : hidePassIcon}
                    />
                  </Button>
                )}
                {htmlFor === "newPassword" && (
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    <Img
                      w="25px"
                      src={showNewPassword ? showPassIcon : hidePassIcon}
                    />
                  </Button>
                )}
                {htmlFor === "confirmNewPassword" && (
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setConfirmPassword(!showConfirmPassword)}
                  >
                    <Img
                      w="25px"
                      src={showConfirmPassword ? showPassIcon : hidePassIcon}
                    />
                  </Button>
                )}
              </InputRightElement>
            </InputGroup>
          )}
          {error && touched && <Text color="red">{error}</Text>}
        </FormControl>
      </WrapItem>
    );
  };
  console.log({ passwordMutation });
  return (
    <>
      <Center mb={4}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(values) => {
            return (
              <>
                <Form mt={6} className="company-new-form">
                  <Center my={2} fontSize="2xl">
                    {/* {"Change Password"} */}
                  </Center>
                  <Wrap spacing="30px" justify="center" my={2}>
                    <Field name="oldPassword">
                      {({ field, form }) => (
                        <CustomInput
                          field={field}
                          form={form}
                          title={"Old Password"}
                          htmlFor="oldPassword"
                          error={values.errors.oldPassword}
                          touched={values.touched.oldPassword}
                          extra="password"
                          type="password"
                        />
                      )}
                    </Field>
                  </Wrap>
                  <Wrap spacing="30px" justify="center" my={2}>
                    <Field name="newPassword">
                      {({ field, form }) => (
                        <CustomInput
                          field={field}
                          form={form}
                          title={"New Password"}
                          htmlFor="newPassword"
                          error={values.errors.newPassword}
                          touched={values.touched.newPassword}
                          extra="password"
                          type="password"
                        />
                      )}
                    </Field>
                  </Wrap>
                  <Wrap spacing="30px" justify="center" my={2}>
                    <Field name="confirmNewPassword">
                      {({ field, form }) => (
                        <CustomInput
                          field={field}
                          form={form}
                          title={"Confirm New Password"}
                          htmlFor="confirmNewPassword"
                          error={values.errors.confirmNewPassword}
                          touched={values.touched.confirmNewPassword}
                          extra="password"
                          type="password"
                        />
                      )}
                    </Field>
                  </Wrap>
                  <Button
                    mt={4}
                    colorScheme="blue"
                    spinner={<Spinner size="md" color="white" />}
                    disabled={!(values.isValid && values.dirty)}
                    // isLoading={loginMutation.isLoading}
                    type={!passwordMutation?.isLoading ? "submit" : "button"}
                    isLoading={passwordMutation.isLoading}
                  >
                    {"Change Password"}
                  </Button>
                </Form>
              </>
            );
          }}
        </Formik>
      </Center>
    </>
  );
};

export default PasswordChangeModal;
