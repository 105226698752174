import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Stack,
  Text,
  FormControl,
  FormLabel,
  HStack,
} from "@chakra-ui/react";
import axios from "axios";
import MaterialTable from "material-table";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../utilities/hooks/auth-hook";
import { BsThreeDots } from "react-icons/bs";
import { BiShowAlt } from "react-icons/bi";
import { RiEdit2Line, RiDeleteBin6Line } from "react-icons/ri";
import Loader from "../../../assets/Loader";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { CgMore } from "react-icons/cg";
import { AiOutlineSmallDash } from "react-icons/ai";
export default function DividendTable() {
  const { useState } = React;
  const [selectedOption, setselectedOption] = React.useState([]);

  const [options, setoptions] = React.useState([]);

  const handleChange = (selectedOption) => {
    console.log(selectedOption);
    setselectedOption(selectedOption);
    // getDividends.refetch();
  };
  const { token } = useAuth();
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [profit, setProfite] = useState(null);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const getDividends = useQuery(
    `getDividends ${selectedOption.id}`,
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}dividends?profit_id=${selectedOption.id}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setData(res.data.data.dividend);
        setProfite(res?.data?.data?.profit?.money_amount);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  console.log({ getDividends });
  const [columns, setColumns] = useState([
    { title: "Name", field: "stakeholder.name" },
    // {
    //   title: "Year ",
    //   field: "year",
    // },

    {
      title: "Dividend ",
      field: "total_payable",
      // render: (dividend) => Number(dividend?.total_payable).toLocaleString(),
      render: (dividend) =>
        (Math.round(dividend.total_payable * 100) / 100).toLocaleString(),
    },

    {
      title: "Net Dividend ",
      field: "stakeholder.dividend",
      render: (amount) =>
        Number(amount?.stakeholder?.dividend).toLocaleString(),
    },
    {
      title: "Action",
      render: (rowData) => {
        return <DropMenu rowData={rowData} />;
      },
    },
  ]);

  const getyear = useQuery(
    "getyear",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}finalized-profits`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setoptions([]);

        for (let i = 0; i < res.data.data.length; i++) {
          console.log(i);

          setoptions((prevOpt) =>
            prevOpt.concat({
              title: `${res.data.data[i].start_date} - ${res.data.data[i].end_date} `,
              id: `${res.data.data[i].id}`,
            })
          );
        }

        // setoptions(res.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  console.log(getDividends.data);
  console.log("date from table page=>", selectedOption);
  function DropMenu({ rowData }) {
    return (
      <Menu>
        <MenuButton
          rounded={"sm"}
          size={"xs"}
          as={IconButton}
          aria-label="Options"
          icon={
            <IconButton
              size={"md"}
              rounded={"sm"}
              border={"1px solid red"}
              icon={<AiOutlineSmallDash />}
              variant={"outline"}
              colorScheme={"blackAlpha"}
            />
          }
          variant="outline"
        />
        <MenuList
          sx={{
            border: "1px solid red",
            bg: "gray",
          }}
        >
          <MenuItem
            icon={<BiShowAlt />}
            onClick={() =>
              navigate("/dividend/createDividend", {
                state: {
                  state: rowData,
                  isReadOnly: true,
                  isEdit: false,
                  isShow: true,
                },
              })
            }
          >
            Show
          </MenuItem>
          <MenuItem
            icon={<RiEdit2Line />}
            onClick={() =>
              navigate("/dividend/createDividend", {
                state: {
                  state: rowData,
                  isReadOnly: false,
                  isEdit: true,
                  isShow: false,
                },
              })
            }
          >
            Edit
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }
  return (
    <Box w={"100%"}>
      <HStack
        spacing={8}
        sx={{
          // border: "1px solid red",
          justifyContent: "space-around",
        }}
      >
        <Box
          align={"left"}
          justifyContent={"left"}
          // borderWidth={"2px"}
          w={"34vh"}
          paddingLeft={"10px"}
        >
          <FormControl zIndex={50} py={2} textAlign={"left"}>
            <FormLabel fontWeight={"bolder"}>Select Year</FormLabel>
            <Select
              styles={{ textAlign: "left" }}
              w={"20vh"}
              // id={"nameid"}
              placeholder="select"
              getOptionLabel={(options) => options.title}
              getOptionValue={(options) => options.id}
              value={selectedOption}
              onChange={(newSelection) => {
                console.log(newSelection);
                handleChange(newSelection);
              }}
              options={options}
            />
          </FormControl>
        </Box>
        {profit !== null && (
          <Text fontWeight={"bolder"} justifyContent={"end"}>
            Total Profit: {Number(profit).toLocaleString("en-US")} birr
          </Text>
        )}
      </HStack>
      {getDividends?.isFetching ? (
        <Loader />
      ) : (
        <Stack>
          <MaterialTable
            title="Dividend List"
            columns={columns}
            data={data}
            options={{
              filtering: true,
              actionsColumnIndex: -1,
              headerStyle: {
                backgroundColor: "#E82481",
                color: "#FFF",
              },
            }}
          />
        </Stack>
      )}
    </Box>
  );
}
