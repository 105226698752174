import React, { useState } from "react";
import { Box, Button } from "@chakra-ui/react";
import MaterialTable from "material-table";

export default function DetailTable(props) {
  const { data, columns, title } = props;
  // const [columns, setColumns] = useState([
  //   { title: "Date", field: "grand" },
  //   {
  //     title: "Payable ",
  //     field: "code",
  //   },
  //   {
  //     title: "Plowed Back dividend ",
  //     field: "code",
  //   },
  //   {
  //     title: "Total paid up capital ",
  //     field: "code",
  //   },
  //   {
  //     title: "Total paid up captal ",
  //     field: "code",
  //   },
  //   {
  //     title: "Weighted Average of Plowed Back Dividend ",
  //     field: "quantity",
  //   },
  //   {
  //     title: "Net Payable ",
  //     field: "quantity",
  //   },
  // ]);

  // const [data, setData] = useState([
  //   {
  //     grand: "test huw",
  //     code: "11,111,000",
  //     color: "222,000,000",
  //     price: "100",
  //     quantity: "222,000,000",
  //     image: "test",
  //   },
  //   {
  //     grand: "test",
  //     code: "222,000,000",
  //     color: "green",
  //     price: "100",
  //     quantity: "222,000,000",
  //     image: "test",
  //   },
  //   {
  //     grand: "test",
  //     code: "33,333,000",
  //     color: "blue",
  //     price: "100",
  //     quantity: "222,000,000",
  //     image: "test",
  //   },
  // ]);

  return (
    <Box>
      <MaterialTable
        title={title}
        columns={columns}
        data={data}
        options={{
          filtering: true,

          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: "#E82481",
            color: "#FFF",
          },
        }}
      />
    </Box>
  );
}
