import {
  Box,
  Button,
  Center,
  CircularProgress,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Progress,
  Stack,
  Switch,
  Text,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Checkbox,
} from "@chakra-ui/react";
import React from "react";
import FormComp from "../../utilities/components/FormComp";
import * as Yup from "yup";
import Select from "react-select";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { useAuth } from "../../utilities/hooks/auth-hook";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";
import { PrintContextConsumer } from "react-to-print";
import FormCompamount from "../../utilities/components/FormCompamount";
export default function Historypage() {
  const toSqlDatetime = (inputDate) => {
    var dd = String(inputDate.getDate()).padStart(2, "0");
    var mm = String(inputDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = inputDate.getFullYear();
    inputDate = yyyy + "-" + mm + "-" + dd;
    return inputDate;
  };
  const [profitedate, setprofitedate] = React.useState(new Date());
  const [Frompayable, setFrompayable] = React.useState("");
  const [selectedOption, setselectedOption] = React.useState(null);
  const [selectedOption2, setselectedOption2] = React.useState([]);
  const [Alldata, setAlldata] = React.useState();
  const [options, setoptions] = React.useState([]);
  const [options2, setoptions2] = React.useState([]);
  const [Data, setData] = React.useState([]);
  const [selectedDate, handleDateChange] = React.useState(new Date());
  const [Message, setMessage] = React.useState("false");
  const handleChange = (selectedOption) => {
    setselectedOption(selectedOption);
  };
  const handleChange2 = (selectedOption) => {
    setselectedOption2(selectedOption);
  };
  const navigate = useNavigate();
  const { token } = useAuth();
  const toast = useToast();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const shareMutation = useMutation(
    async (newData) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "previous-shares",
        newData,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );
  const ShareMutationHandler = async (values) => {
    const {
      name,
      capital,
      dividend,
      phone,
      tax,
      additionAmount,
      Acrv,
      amount,
      plobackdate,
      cashoutdate,
      transferdate,
      shererequestdate,
      amb,
      frompayable,
      av,
      chashoutdate,
      receiver_name,
      prommised,
      transfer_amount,
      cashout_amount,
      acpv,
      share_request_amount,
    } = values;
    console.log({ selectedOption });
    try {
      shareMutation.mutate(
        {
          name: name,
          phone: phone,
          dividend: dividend,
          capital: capital,
          plowback_created_date: plobackdate,
          additional_payment: additionAmount,
          acrv_no: Acrv,
          tax: tax,
          dividend: dividend,
          plowback_amount: amb,
          cashout_amount: parseFloat(cashout_amount),
          acpv_no: acpv,
          cashout_date: cashoutdate,
          transfer_amount: transfer_amount,
          transfer_to: selectedOption2?.id,
          transfer_date: transferdate,
          cashout_date: chashoutdate,
          av_no: av,
          model: Data,
          budjet_year: 2010,
          from_payable: frompayable,
          request_share_amount: share_request_amount,
          request_share_date: shererequestdate,
          prommised: prommised,
        },

        {
          onSuccess: (responseData) => {
            toast({
              title: "Share created successfully",
              status: "success",
              duration: 2500,
              isClosable: true,
            });
            navigate("/Stake-holder");
          },
          onError: (responseData) => {
            toast({
              title: `${responseData?.response?.data?.message}`,
              status: "error",
              duration: 2500,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {
      console.log(err);
      toast({
        title: "Error when Creating",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };
  const getStakeHoldername = useQuery(
    "getStakeHoldername",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}stakeholders`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setoptions(res.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  const getCaptitalG = useQuery(
    "getCaptitalG",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}capital-growths`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        var id = res.data.data[0].model;
        setData(id);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  // console.log(Data.length);
  const getInformation = useQuery(
    "getInformation" + selectedOption?.id,
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}stakeholders/${
          selectedOption?.id
        }?model=${Data}&budjetYear=${profitedate.getFullYear() - 9}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!selectedOption,
      onSuccess: (res) => {
        console.log(res.data.data);
        setAlldata(res.data.data);
        if (setoptions2.length !== 0) {
          setoptions2([]);
        }
        for (let i = 0; i < res.data.data.capital_growths.length; i++) {
          console.log(i);

          setoptions2((prevOpt) =>
            prevOpt.concat({
              title: `${res.data.data.capital_growths[i].start_date}/
              ${
                res.data.data.capital_growths[i].share_growth *
                res.data.data.stakeholder?.allowed_capital_growth
              } birr`,
              id: `${res.data.data.capital_growths[i].id}`,
            })
          );
        }
        // setoptions(res.data.data);
      },
      onError: (responseData) => {
        console.log(responseData.response.data.message);
        setMessage(responseData.response.data.message);
      },
    }
  );
  console.log({ getStakeHoldername });
  console.log({ getInformation });
  console.log(Alldata?.current_dividend);

  return (
    <Stack
      bg={"white"}
      boxShadow={"base"}
      w={"100%"}
      p={8}
      spacing={6}
      justify={"center"}
      align={"center"}
    >
      <Box w={"80%"}>
        <Heading> Stakeholder Information</Heading>
        <Formik
          initialValues={{
            name: "",
            capital: "",
            dividend: "",
            phone: "",
            tax: "",
            status: false,
            additionAmount: "",
            Acrv: "",
            amount: 0,
            chashoutdate: "",
            plobackdate: "",
            cashoutdate: "",
            transferdate: "",
            shererequestdate: "",
            amb: 0,
            frompayable: "",
            av: "",
            receiver_name: "",
            transfer_amount: 0,
            cashout_amount: "",
            acpv: "",
            share_request_amount: "",
            prommised: false,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Required !"),
            capital: Yup.string().required("Required !"),
            phone: Yup.string().required("Required !"),
            status: Yup.bool().required("Required !"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            ShareMutationHandler(values);
            console.log({ values });
            setSubmitting(false);
          }}
        >
          {(formik) => {
            console.log({ formik });
            return (
              <form onSubmit={formik.handleSubmit}>
                <FormComp id={"name"} label={"Name"} formik={formik} />

                <FormComp id={"phone"} label={"Phone"} formik={formik} />
                <FormComp
                  id={"capital"}
                  label={"Capital(birr)"}
                  formik={formik}
                />
                <FormComp
                  id={"dividend"}
                  label={"dividend(birr)"}
                  formik={formik}
                />
                <Tabs mt={2}>
                  <TabList>
                    <Tab>Plowback</Tab>
                    <Tab>Cashout</Tab>
                    <Tab>Transfer</Tab>
                    <Tab>Request Share</Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      <Box float={"left"}>
                        <Text textAlign={"left"}> Select Date</Text>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            clearable
                            // views={["year"]}
                            selectsRange={true}
                            id={"plobackdate"}
                            value={formik.values.plobackdate}
                            placeholder="Date"
                            onChange={(value) => {
                              formik.setFieldValue(
                                "plobackdate",
                                toSqlDatetime(value)
                              );
                            }}
                            // onChange={(date) => handleDateChange(date)}
                            // minDate={new Date()}
                            format="dd/MM/yyyy"
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                      <br />
                      <Box mt={10}>
                        <FormCompamount
                          id={"amount"}
                          label={"Amount"}
                          type="number"
                          setFrompayable={setFrompayable}
                          formik={formik}
                        />
                      </Box>

                      <FormComp
                        id={"av"}
                        label={"AV number"}
                        type="name"
                        formik={formik}
                      />
                      <FormComp
                        id={"tax"}
                        label={"Tax (10%)"}
                        type="number"
                        formik={formik}
                      />
                      <hr />
                      <Text>Additional Payment</Text>
                      <HStack w={"100%"}>
                        <Box w={"100%"}>
                          <FormComp
                            id={"additionAmount"}
                            label={"Amount"}
                            formik={formik}
                            type={"number"}
                          />
                        </Box>
                        <Box w={"100%"}>
                          <FormComp
                            id={"Acrv"}
                            label={"ACRV"}
                            formik={formik}
                          />
                        </Box>
                      </HStack>
                    </TabPanel>
                    <TabPanel>
                      <Stack>
                        <Box>
                          <Text textAlign={"left"}> Select Date</Text>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              clearable
                              // views={["year"]}
                              selectsRange={true}
                              id={"chashoutdate"}
                              value={formik.values.chashoutdate}
                              placeholder=" Date"
                              onChange={(value) => {
                                formik.setFieldValue(
                                  "chashoutdate",
                                  toSqlDatetime(value)
                                );
                              }}
                              // onChange={(date) => handleDateChange(date)}
                              // minDate={new Date()}
                              format="dd/MM/yyyy"
                            />
                          </MuiPickersUtilsProvider>
                        </Box>
                        <FormComp
                          id={"cashout_amount"}
                          label={"Enter Cashout Amount(birr)"}
                          formik={formik}
                        />

                        <FormComp
                          id={"acpv"}
                          label={"Enter ACPV"}
                          formik={formik}
                        />
                      </Stack>
                    </TabPanel>
                    <TabPanel>
                      <Stack>
                        <Box>
                          <Text textAlign={"left"}> Select Date</Text>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              clearable
                              // views={["year"]}
                              selectsRange={true}
                              id={"transferdate"}
                              value={formik.values.transferdate}
                              placeholder="Date"
                              onChange={(value) => {
                                formik.setFieldValue(
                                  "transferdate",
                                  toSqlDatetime(value)
                                );
                              }}
                              // onChange={(date) => handleDateChange(date)}
                              // minDate={new Date()}
                              format="dd/MM/yyyy"
                            />
                          </MuiPickersUtilsProvider>
                        </Box>
                        <FormControl py={2} textAlign={"left"}>
                          <FormLabel>Select receiver Stakeholder</FormLabel>
                          <Select
                            styles={{ textAlign: "left" }}
                            w={"20vh"}
                            id={"name"}
                            // id={"nameid"}
                            placeholder="select"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            value={selectedOption2}
                            onChange={(newSelection) => {
                              console.log(newSelection.id);
                              // formik?.setFieldValue("nameid", newSelection.id);
                              handleChange2(newSelection);
                            }}
                            options={options}
                          />
                        </FormControl>
                        <FormComp
                          id={"transfer_amount"}
                          label={" Transfer Amount"}
                          formik={formik}
                        />
                      </Stack>
                    </TabPanel>
                    <TabPanel>
                      <Stack>
                        <Box>
                          <Text textAlign={"left"}> Select Date</Text>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              clearable
                              // views={["year"]}
                              selectsRange={true}
                              id={"shererequestdate"}
                              value={formik.values.shererequestdate}
                              placeholder="Date"
                              onChange={(value) => {
                                formik.setFieldValue(
                                  "shererequestdate",
                                  toSqlDatetime(value)
                                );
                              }}
                              // onChange={(date) => handleDateChange(date)}
                              // minDate={new Date()}
                              format="dd/MM/yyyy"
                            />
                          </MuiPickersUtilsProvider>
                        </Box>
                        <FormComp
                          id={"share_request_amount"}
                          label={"Enter Share Request Amount (Birr)"}
                          formik={formik}
                        />
                      </Stack>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
                <Button
                  mt={6}
                  type="submit"
                  bg={"#B76A1D"}
                  color={"white"}
                  disabled={
                    formik.values.amount + formik.values.additionAmount >
                    Alldata?.total_cg
                      ? true
                      : false
                  }
                  variant="solid"
                >
                  Submit
                </Button>
              </form>
            );
          }}
        </Formik>
      </Box>
    </Stack>
  );
}
