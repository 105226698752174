import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function BreadCrumb() {
  //Hook
  const navigate = useNavigate();
  const { pathname } = useLocation();

  //Function
  const path = pathname.split("/").filter((x) => x);
  console.log("path=>", path);

  //JSx
  return (
    <div>
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
      >
        {path.map((name, index) => {
          const routeTo = `/${path.slice(0, index + 1).join("/")}`;
          const isLast = index === path.length - 1;
          return isLast ? (
            <BreadcrumbItem key={index}>
              {/* <Text color={"#868686"}>{name}</Text> */}
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem key={index}>
              <BreadcrumbLink onClick={() => navigate(routeTo)}>
                {name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </div>
  );
}
