import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import React, { useEffect } from "react";

export default function FormComp({ formik, id, label, isReadOnly, type }) {
  // useEffect(() => {
  //   if (id == "amount") {
  //     console.log({ ...formik.getFieldProps(id).value });
  //   }
  // }, []);

  return (
    <FormControl
      py={2}
      sx={{
        width: "50%",
        mx: "auto",
      }}
    >
      <FormLabel
        htmlFor={id}
        sx={{
          fontWeight: "bold",
        }}
      >
        {label}
      </FormLabel>
      <Input
        isReadOnly={isReadOnly}
        id={id}
        type={type ? type : "text"}
        size={"lg"}
        variant="unstyled"
        sx={{
          border: "1px solid gray",
          py: 1,
        }}
        // onChange={(e) => formik?.setFieldValue(id, e.target.value)}
        {...formik.getFieldProps(id)}
      />
      {!isReadOnly && formik.touched[id] && formik.errors[id] ? (
        <FormHelperText fontSize={"xs"} color={"red.600"}>
          {formik.errors[id]}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}
