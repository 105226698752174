import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Stack,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import FormComp from "../../utilities/components/FormComp";
import * as Yup from "yup";
import Select from "react-select";

import { useAuth } from "../../utilities/hooks/auth-hook";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";
export default function CreateTransfers() {
  const [selectedOption, setselectedOption] = React.useState([]);
  const [selectedOption2, setselectedOption2] = React.useState([]);

  const [options, setoptions] = React.useState([]);

  const handleChange = (selectedOption) => {
    setselectedOption(selectedOption);
  };
  const handleChange2 = (selectedOption) => {
    setselectedOption2(selectedOption);
  };
  const {
    state: { state, isReadOnly, isEdit, isShow },
  } = useLocation();
  const navigate = useNavigate();
  const { token } = useAuth();
  const toast = useToast();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const TransfersMutation = useMutation(
    async (newData) =>
      isEdit
        ? await axios.patch(
            process.env.REACT_APP_BACKEND_URL + "transfers" + "/" + state.id,
            newData,
            {
              headers,
            }
          )
        : await axios.post(
            process.env.REACT_APP_BACKEND_URL + "transfers",
            newData,
            {
              headers,
            }
          ),
    {
      retry: false,
    }
  );
  const TransfersMutationHandler = async (values) => {
    const { amount } = values;
    console.log({ selectedOption });
    try {
      TransfersMutation.mutate(
        { from: selectedOption.id, to: selectedOption2.id, amount: amount },
        {
          onSuccess: (responseData) => {
            toast({
              title: isEdit
                ? "transfers created successfully"
                : "Share created successfully",
              status: "success",
              duration: 2500,
              isClosable: true,
            });
            navigate("/transfers");
          },
          onError: (err) => {
            console.log(err);
            toast({
              title: `${err.message}`,
              status: "error",
              duration: 2500,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {
      console.log(err);
      toast({
        title: `${err.message}`,
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };
  const getStakeHoldername = useQuery(
    "getStakeHoldername",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}stakeholders`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setoptions(res.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  console.log({ getStakeHoldername });

  return (
    <Stack
      bg={"white"}
      boxShadow={"base"}
      w={"100%"}
      p={8}
      spacing={6}
      justify={"center"}
      align={"center"}
    >
      <Box w={"80%"} h={"60vh"}>
        <Heading mb={"40px"}> Transfer</Heading>
        <Formik
          initialValues={{
            transferor_name: state !== null ? state.from.name : "",
            receiver_name: state !== null ? state.to.name : "",
            amount: state !== null ? state.money_amount : "",
          }}
          validationSchema={Yup.object({
            // nameid: Yup.string().required("Required !"),
            amount: Yup.number()
              .required("Required !")
              .max(
                state !== null
                  ? state.from.money_amount
                  : selectedOption?.current_capital
              ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            TransfersMutationHandler(values);
            console.log({ values });
            setSubmitting(false);
          }}
        >
          {(formik) => {
            console.log({ formik });
            return (
              <form onSubmit={formik.handleSubmit}>
                {isEdit ? (
                  <>
                    <FormComp
                      id={"transferor_name"}
                      label={"transferor name"}
                      formik={formik}
                      isReadOnly={isReadOnly}
                    />
                    <FormComp
                      id={"receiver_name"}
                      label={"Receiver name"}
                      formik={formik}
                      isReadOnly={isReadOnly}
                    />
                  </>
                ) : isShow ? (
                  <>
                    <FormComp
                      id={"transferor_name"}
                      label={"Transferor name"}
                      formik={formik}
                      isReadOnly={isReadOnly}
                    />
                    <FormComp
                      id={"receiver_name"}
                      label={"Receiver name"}
                      formik={formik}
                      isReadOnly={isReadOnly}
                    />
                  </>
                ) : (
                  <>
                    <FormControl py={2} textAlign={"left"}>
                      <FormLabel>Select transferor Stakeholder</FormLabel>
                      <Select
                        styles={{ textAlign: "left" }}
                        w={"20vh"}
                        id={"name"}
                        // id={"nameid"}
                        placeholder="select"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        value={selectedOption}
                        onChange={(newSelection) => {
                          console.log(newSelection.id);
                          // formik?.setFieldValue("nameid", newSelection.id);
                          handleChange(newSelection);
                        }}
                        options={options}
                      />
                    </FormControl>

                    <FormControl py={2} textAlign={"left"}>
                      <FormLabel>Select receiver Stakeholder</FormLabel>
                      <Select
                        styles={{ textAlign: "left" }}
                        w={"20vh"}
                        id={"name"}
                        // id={"nameid"}
                        placeholder="select"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        value={selectedOption2}
                        onChange={(newSelection) => {
                          console.log(newSelection.id);
                          // formik?.setFieldValue("nameid", newSelection.id);
                          handleChange2(newSelection);
                        }}
                        options={options}
                      />
                    </FormControl>
                  </>
                )}
                <FormComp id={"amount"} label={"Amount"} formik={formik} />
                {/* <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="status" mb="0">
                    Status
                  </FormLabel>
                  <Switch
                    id="status"
                    // value={formik.initialValues.status}
                    name="status"
                    defaultValue={true}
                    onChange={() => {
                      // console.log();
                      formik.setFieldValue("status", !formik.values.status);
                    }}
                  />
                </FormControl> */}
                {!isShow && (
                  <Button
                    disabled={formik.isSubmitting}
                    mt={6}
                    type="submit"
                    bg={"#B76A1D"}
                    color={"white"}
                    variant="solid"
                  >
                    Submit
                  </Button>
                )}
              </form>
            );
          }}
        </Formik>
      </Box>
    </Stack>
  );
}
