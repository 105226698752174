import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import MaterialTable from "material-table";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../utilities/hooks/auth-hook";
import { BsThreeDots } from "react-icons/bs";
import { BiShowAlt } from "react-icons/bi";
import { RiEdit2Line, RiDeleteBin6Line } from "react-icons/ri";
import Loader from "../../../assets/Loader";
import { IoMdTennisball } from "react-icons/io";

export default function ProfitTable() {
  const { useState } = React;
  const { token } = useAuth();
  let navigate = useNavigate();
  const [Id, setId] = useState(null);
  const [IdT, setIdT] = useState(null);

  const [data, setData] = useState([]);
  const toast = useToast();
  // const [count, setCount] = useState(0);
  // const [queryReload, setQueryReload] = useState(false);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  function DropMenu({ rowData }) {
    return (
      <Menu>
        <MenuButton
          rounded={"sm"}
          size={"xs"}
          as={IconButton}
          aria-label="Options"
          icon={
            <IconButton
              size={"xs"}
              rounded={"sm"}
              icon={<BsThreeDots />}
              variant={"outline"}
              colorScheme={"blackAlpha"}
            />
          }
          variant="outline"
        />
        <MenuList>
          <MenuItem
            icon={<BiShowAlt />}
            onClick={() =>
              navigate("/dividend/createprofit", {
                state: {
                  state: rowData,
                  isReadOnly: true,
                  isEdit: false,
                  isShow: true,
                },
              })
            }
          >
            Show
          </MenuItem>
          <MenuItem
            icon={<RiEdit2Line />}
            onClick={() =>
              navigate("/dividend/createprofit", {
                state: {
                  state: rowData,
                  isReadOnly: false,
                  isEdit: true,
                  isShow: false,
                },
              })
            }
          >
            Edit
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }
  const getProfits = useQuery(
    "getProfits",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}profits`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setData(res.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  // console.log({ count, queryReload, getProfits });
  const getProfitsfinalize = useQuery(
    [`getProfitsfinalize`, Id],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}profits/${Id}/finalize`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        getProfits?.refetch();
        toast({
          title: "Profite Finalized successfully",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
      },
      onError: (err) => {
        console.log(err);

        Id !== null &&
          toast({
            title: "Profite not Finalized ",
            status: "error",
            duration: 2500,
            isClosable: true,
          });
      },
    }
  );
  const getProfitsTransfer = useQuery(
    [`getProfitsTransfer`, IdT],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}transfer-remaining-amount?profit_id=${IdT}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: IdT !== null,
      onSuccess: (res) => {
        getProfits?.refetch();
        toast({
          title: "Transfered  successfully",
          status: "success",
          duration: 2500,
          isClosable: true,
        });
      },
      onError: (err) => {
        console.log(err);

        Id !== null &&
          toast({
            title: "Profite not Finalized ",
            status: "error",
            duration: 2500,
            isClosable: true,
          });
      },
    }
  );
  console.log({ getProfits });
  const [columns, setColumns] = useState([
    { title: "Start Date", field: "start_date" },
    { title: "End Date", field: "end_date" },
    {
      title: " Profit Amount",
      field: "money_amount",
    },
    {
      title: " Remaining_Share_amount",
      field: "remaining_amount",
    },
    {
      title: "Publish ",
      field: "is_finalized",
    },
    {
      title: "Transfer Remaining Share",
      render: (rowData) => {
        return (
          rowData.remaining_amount !== "0" && (
            <Button
              mt={"20px"}
              mb={"20px"}
              ml={"10px"}
              mr={"20px"}
              bg={"#B76A1D"}
              color={"white"}
              variant="solid"
              onClick={() => {
                setIdT(rowData.id);
              }}
            >
              Transfer
            </Button>
          )
        );
      },
    },
    {
      title: "Finalize",
      render: (rowData) => {
        return (
          rowData.is_finalized === "False" && (
            <Button
              mt={"20px"}
              mb={"20px"}
              ml={"10px"}
              mr={"20px"}
              bg={"#B76A1D"}
              color={"white"}
              variant="solid"
              onClick={() => {
                setId(rowData.id);
              }}
            >
              Finalize
            </Button>
          )
        );
      },
    },
    {
      title: "Action",
      render: (rowData) => {
        return <DropMenu rowData={rowData} />;
      },
    },
  ]);

  // const [data, setData] = useState([
  //   {
  //     grand: "test",
  //     code: 11111,
  //     color: "red",
  //     price: "100",
  //     quantity: "222,000,000",
  //     image: "test",
  //   },
  //   {
  //     grand: "test",
  //     code: 2222,
  //     color: "green",
  //     price: "100",
  //     quantity: "104,455,005",
  //     image: "test",
  //   },
  //   {
  //     grand: "test",
  //     code: 33333,
  //     color: "blue",
  //     price: "100",
  //     quantity: "222,000,000",
  //     image: "test",
  //   },
  // ]);
  return (
    <Box w={"100%"}>
      {getProfits?.isFetching ? (
        <Loader />
      ) : (
        <MaterialTable
          title="Profit List"
          columns={columns}
          data={data}
          options={{
            filtering: true,

            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "#E82481",
              color: "#FFF",
            },
          }}
        />
      )}
    </Box>
  );
}
