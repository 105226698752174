import { Box, Button, Center, Stack } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import ProfitTable from "./Table/ProfitTable";

export default function Profit() {
  let navigate = useNavigate();

  return (
    <Stack padding={10}>
      <Box>
        <Button
          mt={"20px"}
          mb={"20px"}
          ml={"10px"}
          mr={"20px"}
          float={"right"}
          onClick={() => {
            navigate("/profit/Createprofit", {
              state: {
                state: null,
                isReadOnly: false,
                isEdit: false,
                isShow: false,
              },
            });
          }}
          // w={"30vh"}
          leftIcon={<AddIcon />}
          bg={"#B76A1D"}
          color={"white"}
          variant="solid"
        >
          Create Profit
        </Button>
      </Box>
      <Center>
        <ProfitTable></ProfitTable>
      </Center>
    </Stack>
  );
}
