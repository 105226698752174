import { Box, Heading, HStack, Stack, Wrap } from "@chakra-ui/react";
import React from "react";
import CrossSiteRevenue from "./CrossSiteRevenue";
import FrequentlyPurchased from "./FrequentlyPurchased";
import OutdoorSales from "./OutdoorSales";

const CrosssiteActivity = () => {
  return (
    <>
      <Heading pl="5%">Company Activity</Heading>
      <Stack direction={{ base: "column", md: "column", lg: "row", xl: "row" }}>
        <Box justifySelf={"center"} w={{ base: "100%", md: "45%" }}>
          <CrossSiteRevenue />
        </Box>
        <Box
          justifySelf={{ base: "center", md: "center" }}
          w={{ base: "100%", md: "55%" }}
        >
          <OutdoorSales />
        </Box>
      </Stack>
    </>
  );
};

export default CrosssiteActivity;
