import { Box, Center, Stack, Text, useMediaQuery } from "@chakra-ui/react";
import React, { PureComponent } from "react";
import { useQuery } from "react-query";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useAuth } from "../../../utilities/hooks/auth-hook";
import axios from "axios";
function formatCompactNumber(number) {
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  return formatter.format(number);
}
const CrossSiteRevenue = () => {
  const [isSmallerThan992] = useMediaQuery("(max-width: 992px)");
  const [isSmallerThan756] = useMediaQuery("(max-width: 756px)");
  const [isSmallerThan480] = useMediaQuery("(max-width: 480px)");
  const [isSmallerThan350] = useMediaQuery("(max-width: 350px)");

  const { token } = useAuth();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const revenueData = useQuery(
    `revenueData`,
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}profits`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: true,
      enabled: !!token,
    }
  );

  console.log({ revenueData: revenueData?.data?.data?.data });

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div>
          <p>{`Year :${label}`}</p>
          <p
            style={{
              fontWeight: "bold",
            }}
          >{`Revenue :${Number(payload[0].value).toLocaleString()} ETB`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <Box>
      <Stack>
        <Center>Annual Revenue</Center>
        <BarChart
          height={450}
          data={revenueData?.data?.data?.data}
          margin={{
            top: 20,
            right: isSmallerThan350
              ? 0
              : isSmallerThan480
              ? 0
              : isSmallerThan756
              ? 5
              : isSmallerThan992
              ? 15
              : 30,
            left: isSmallerThan350
              ? 0
              : isSmallerThan480
              ? 0
              : isSmallerThan756
              ? 5
              : isSmallerThan992
              ? 16
              : 10,
            bottom: 5,
          }}
          width={
            isSmallerThan350
              ? 300
              : isSmallerThan480
              ? 360
              : isSmallerThan756
              ? 480
              : isSmallerThan992
              ? 730
              : 480
          }
          fontSize={
            isSmallerThan350
              ? 11
              : isSmallerThan480
              ? 14
              : isSmallerThan756
              ? 16
              : isSmallerThan992
              ? 18
              : 16
          }
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="budget_year" />
          <YAxis
            tickFormatter={formatCompactNumber}
            // dataKey={"money_amount"}

            // dataKey={(v) => formatCompactNumber(v.money_amount).toString()}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            content={
              <Text
                sx={{
                  fontWeight: "bold",
                  color: "#AC7729",
                }}
              >
                Revenue
              </Text>
            }
          />
          {/* <Bar dataKey="Expense" fill="#FAD03C" /> */}
          <Bar dataKey="money_amount" fill="#AC7729" />
        </BarChart>
      </Stack>
    </Box>
  );
};

export default CrossSiteRevenue;
