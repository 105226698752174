import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Spinner,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { Formik, Field } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import DateFnsUtils from "@date-io/date-fns";
import MeetingCard from "./Card";
import moment from "moment/moment";
import { useNavigate } from "react-router";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { ArrowUpIcon } from "@chakra-ui/icons";
import { useToast } from "@chakra-ui/react";

const validationSchema = Yup.object({
  meeting: Yup.string()
    .required("Meeting title is required")
    .min(8, "must be at least 8 character"),
  location: Yup.string().required("Meeting location is required"),
  note: Yup.string(),
  time: Yup.date().required("Meeting time is required"),
});

const Meeting = () => {
  const [meeting, setMeeting] = useState([]);
  const [showMeeting, setShowMeeting] = useState(false);
  const navigate = useNavigate();
  const { token } = useAuth();
  const queryClient = useQuery();
  const toast = useToast();
  console.log("this meetig data=>", { meeting });
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const meetingMutation = useMutation(
    async (newData) =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}meetings`,
        newData,

        {
          headers,
        }
      ),
    {
      onSuccess: () => {
        toast({
          title: "Meeting created.",
          description: "Created new meeting successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        meetingData.refetch();
      },
    },
    {
      retry: false,
    }
  );
  console.log("meetingMutation =>", meeting);
  const meetingData = useQuery(
    ["meetingDataForCard"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}meetings`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );
  return (
    <VStack>
      <Flex
        sx={{
          justifyContent: "space-around",
          width: "100%",
          alignItems: "center",
          justifyItems: "center",
          my: 5,
          pb: 10,
          borderBottom: "1px solid gray",
        }}
      >
        <Text
          sx={{
            color: "#B51D64",
            fontWeight: "bold",
            fontSize: "28px",
          }}
        >
          Create new meeting
        </Text>
        {showMeeting ? (
          <IconButton
            icon={<ArrowUpIcon />}
            sx={{
              backgroundColor: "#B51D64",
              color: "#fff",
              borderRadius: "5px",
              fontWeight: "bold",
              padding: "10px",
            }}
            onClick={() => setShowMeeting(!showMeeting)}
          >
            {"Create new meeting"}
          </IconButton>
        ) : (
          <Button
            sx={{
              backgroundColor: "#B51D64",
              color: "#fff",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
            onClick={() => setShowMeeting(!showMeeting)}
          >
            {"Create new meeting"}
          </Button>
        )}
      </Flex>
      {showMeeting && (
        <Box bg="white" p={6} w={"40%"}>
          <Formik
            initialValues={{
              meeting: "",
              location: "",
              note: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              setMeeting(values);
              meetingMutation.isLoading && actions.setSubmitting(false);
              actions.resetForm();
              showMeeting(false);
            }}
          >
            {(props) => (
              <VStack
                as={"form"}
                spacing={4}
                align="flex-start"
                onSubmit={props.handleSubmit}
              >
                <FormControl
                  isInvalid={!!props.errors.meeting && props.touched.meeting}
                >
                  <FormLabel htmlFor="Meeting Title">Meeting</FormLabel>
                  <Field
                    as={Input}
                    p={4}
                    placeholder="Meeting Title"
                    id="meeting"
                    name="meeting"
                    variant="filled"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.meeting}
                  />
                  <FormErrorMessage> {props.errors.meeting} </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!props.errors.location && props.touched.location}
                >
                  <FormLabel htmlFor="Location">Location</FormLabel>
                  <Field
                    p={4}
                    as={Input}
                    placeholder="Location"
                    id="location"
                    name="location"
                    variant="filled"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.location}
                  />
                  <FormErrorMessage>{props.errors.location}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={!!props.errors.note && props.touched.note}
                >
                  <FormLabel htmlFor="note">Note</FormLabel>
                  <Field
                    p={4}
                    as={Input}
                    placeholder="Enter note for meeting"
                    id="note"
                    name="note"
                    variant="filled"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.note}
                  />
                </FormControl>
                <FormControl
                  isInvalid={!!props.errors.time && props.touched.time}
                >
                  <FormLabel htmlFor="time">Time</FormLabel>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="time"
                      name="time"
                      format="MM/dd/yyyy"
                      minDate={new Date()}
                      defaultValue={props.values.time}
                      value={props.values.time}
                      onChange={(value) =>
                        props.setFieldValue(
                          "time",

                          moment(value).format("YYYY-MM-DD HH:mm:ss")
                        )
                      }
                    />
                  </MuiPickersUtilsProvider>
                  <FormErrorMessage>{props.errors.time}</FormErrorMessage>
                </FormControl>

                <Button
                  type="submit"
                  disabled={!!props.errors.location || !!props.errors.meeting}
                  colorScheme="purple"
                  width="full"
                  isLoading={props.isSubmitting || meetingMutation.isLoading}
                  onClick={() => {
                    meetingMutation.mutate({
                      title: props.values.meeting,
                      location: props.values.location,
                      time: props.values.time,
                    });
                  }}
                >
                  Create Meeting
                </Button>
              </VStack>
            )}
          </Formik>
        </Box>
      )}
      <Wrap spacing="90px" justify="center" m={8}>
        {meetingData.isLoading && <Spinner />}
        {meetingData.isError && <Text>Something went wrong</Text>}
        {meetingData.isSuccess &&
          meetingData.data?.data?.data?.length === 0 && (
            <Text>No meeting found</Text>
          )}
        {meetingData.isSuccess &&
          meetingData.data?.data?.data?.map((meeting) => (
            <WrapItem key={meeting?.id}>
              <MeetingCard
                refetch={meetingData.refetch}
                key={meeting?.id}
                meetingId={meeting?.id}
                // status={meeting?.status}
                note={meeting?.note}
                today={meeting?.start_date}
                start_meeting={meeting?.start_meeting}
                meetingTittle={
                  meeting?.title?.length > 20
                    ? meeting?.title?.substring(0, 20) + "..."
                    : meeting?.title
                }
                location={
                  meeting?.location?.length > 20
                    ? meeting?.location?.substring(0, 20) + "..."
                    : meeting?.location
                }
                meetingTime={meeting?.time}
                status={meeting?.status}
                onClickAttendees={() =>
                  navigate(`/meeting/attendees/${meeting?.id}`, {
                    state: {
                      meetingTittle: meeting?.title,
                    },
                  })
                }
                onClick={() =>
                  navigate(`/meeting/agenda/${meeting?.id}`, {
                    state: {
                      meetingTittle: meeting?.title,
                    },
                  })
                }
              />
            </WrapItem>
          ))}
      </Wrap>
    </VStack>
  );
};

export default Meeting;
