import React from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Text,
  Wrap,
  WrapItem,
  useToast,
} from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import { AddIcon } from "@chakra-ui/icons";
import { customStyles } from "../../Meeting/Attendance";
import { useMutation } from "react-query";
import axios from "axios";
import { useAuth } from "../../../utilities/hooks/auth-hook";

export const VoteAgenda = () => {
  const [shareholder, setShareholder] = React.useState(null);
  const [proxy, setProxy] = React.useState(null);
  const { token } = useAuth();
  const toast = useToast();
  const handleOption1Change = (selectedOption) => {
    setShareholder(selectedOption);
    console.log(selectedOption);
    setProxy(null);
  };
  const params = useParams();
  const { state } = useLocation();
  const handleOption2Change = (selectedOption) => {
    setProxy(selectedOption);
    console.log(selectedOption);
    setShareholder(null);
  };

  console.log(params);
  console.log(state);

  const isOption1Disabled = proxy !== null;
  const isOption2Disabled = shareholder !== null;
  console.log(params);
  console.log(state);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const voteMutation = useMutation(
    async (data) =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}votes/agendas`,
        data,
        {
          headers,
        }
      ),
    {
      onSuccess: () => {
        toast({
          title: "Vote created successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setShareholder(null);
        setProxy(null);
      },
      onError: (error) => {
        toast({
          title: "Error",
          description: error.response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  return (
    <Box>
      <Text
        fontSize="2xl"
        marginLeft={6}
        fontWeight="bold"
        textAlign="start"
        my={4}
      >
        {`Agenda Vote Management - ${state?.meetingTitle}`}
      </Text>
      <Center
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          fontSize="2xl"
          color={"#E82481"}
          fontWeight="bold"
          textAlign="center"
          mb={4}
        >
          {state?.agendaTitle}
        </Text>
        <Wrap
          spacing="30px"
          justify="space-around"
          width="80%"
          mt={6}
          direction={"row"}
        >
          <WrapItem
            as={Flex}
            direction={"column"}
            align="center"
            justify="center"
          >
            <Heading size="md" my={4}>
              Select Shareholder
            </Heading>

            <Select
              styles={customStyles}
              placeholder="Select Shareholder"
              options={state?.shareholders}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={handleOption1Change}
              value={shareholder}
              isDisabled={isOption1Disabled}
            />
          </WrapItem>
          <WrapItem
            as={Flex}
            direction={"column"}
            align="center"
            justify="center"
          >
            <Heading size="md" my={4}>
              Select Proxy
            </Heading>

            <Select
              styles={customStyles}
              placeholder="Select Proxy"
              options={state?.proxies}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={handleOption2Change}
              value={proxy}
              isDisabled={isOption2Disabled}
            />
          </WrapItem>
        </Wrap>
        {shareholder ? (
          <Box
            width="50%"
            my={10}
            direction={"row"}
            bg={"#F5F5F5"}
            boxShadow={"3px 4px 4px 2px  rgba(0, 0, 0, 0.25)"}
            borderRadius={10}
            p={4}
          >
            <Text fontSize="xl" fontWeight="bold" textAlign="center" mb={4}>
              Shareholder
            </Text>
            <Text fontSize="lg" fontWeight="bold" textAlign="center" mb={4}>
              {shareholder?.name}
            </Text>
            {Number(shareholder?.shares).toLocaleString()} Shares
          </Box>
        ) : null}
        {proxy ? (
          <Box
            width="50%"
            my={10}
            direction={"row"}
            bg={"#F5F5F5"}
            boxShadow={"3px 4px 4px 2px  rgba(0, 0, 0, 0.25)"}
            borderRadius={10}
            p={4}
          >
            <Text fontSize="xl" fontWeight="bold" textAlign="center" mb={4}>
              Proxy
            </Text>
            <Text fontSize="lg" fontWeight="bold" textAlign="center" mb={4}>
              {proxy?.name}
            </Text>

            <Text fontSize="lg" fontWeight="bold" textAlign="center" mb={4}>
              {Number(proxy?.shares).toLocaleString()} Shares
            </Text>
          </Box>
        ) : null}

        <Button
          my={12}
          leftIcon={<AddIcon />}
          bg={"#B76A1D"}
          color={"white"}
          size="md"
          width={"md"}
          variant="solid"
          _hover={{ bg: "#B76A1D" }}
          onClick={() => {
            voteMutation.mutate({
              agenda_id: params?.agendaId,
              meeting_id: state?.meetingId,
              stakeholder_id: shareholder?.id,
              proxy_id: proxy?.id,
            });
          }}
        >
          Vote
        </Button>
      </Center>
    </Box>
  );
};
