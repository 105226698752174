import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Stack,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import SideBar from "./components/SideBar";
import NavBar from "../utilities/components/NaBar";
import MobileDrawer from "./components/MobileDrawer";

export default function StoreManager() {
  //Hook
  const [isLargerThan992] = useMediaQuery("(min-width: 992px)");

  const btnRef = React.useRef();

  const handleClick = (e) => {
    setClick(!click);
  };
  const [click, setClick] = useState(false);

  //Jsx
  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      alignItems="flex-start"
      spacing={0}
      overflow="hidden"
      position="sticky"
      overflowX={"hidden"}
      overflowY={"auto"}
      // position={"sticky"}
      // top={0}
    >
      {isLargerThan992 && <SideBar />}
      <Stack
        h="100vh"
        overflowY={"auto"}
        overflowX={"hidden"}
        minW={{ base: "100%", lg: "81%", xl: "80%", "2xl": "84%" }}
        maxW={{ base: "100%", lg: "81%", xl: "80%", "2xl": "84%" }}
      >
        <NavBar
          btnRef={btnRef}
          handleClick={handleClick}
          setClick={setClick}
          click={click}
        />

        {!isLargerThan992 && (
          <Drawer
            isOpen={click}
            placement="left"
            onClose={handleClick}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <MobileDrawer />
            </DrawerContent>
          </Drawer>
        )}
        <Outlet />
      </Stack>
    </Stack>
  );
}
