import {
  Button,
  Input,
  Box,
  Center,
  Divider,
  FormControl,
  Text,
  useToast,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../../utilities/hooks/auth-hook";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { SyncLoader } from "react-spinners";

const ProfileChangeModal = ({ isOpen, onClose, userId }) => {
  const { user, token, logout } = useAuth();
  const toast = useToast();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const userData = useQuery(
    `userData${user?.id}`,
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}users/${userId}`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: false,
      enabled: !!token && !!userId,
    }
  );
  console.log({ userData });

  const updateProfile = useMutation(
    async (updateProfile) =>
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL + `users/${user?.id}`,
        updateProfile,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );

  const initialValues = {
    firstName: userData?.data?.data?.data?.first_name,
    lastName: userData?.data?.data?.data?.last_name,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {
    const { firstName, lastName } = values;
    try {
      updateProfile.mutate(
        {
          first_name: firstName,
          last_name: lastName,
          role_id: userData?.data?.data?.data?.role_id,
        },
        {
          onSuccess: (responseData) => {
            toast({
              title: "Profile updated successfully.",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            onClose();
          },
          onError: (responseDataError) => {
            console.log({ responseDataError });
            toast({
              title:
                responseDataError?.response?.data?.data ||
                responseDataError?.response?.data?.message ||
                "Profile updated not updated.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          },
        }
      );
      // Redirect the admin to a different page
    } catch (err) {}
  };

  const CustomInput = ({ field, title, htmlFor, type, error, touched }) => {
    return (
      <Box w={{ base: "100%", md: "70%" }}>
        <FormControl isInvalid={error && touched}>
          <Stack spacing="3px">
            <Input
              w={{ base: "280px", lg: "320px" }}
              {...field}
              id={htmlFor}
              placeholder={title}
              type={type}
            />
            {error && touched && <Text color="red">{error}</Text>}
          </Stack>
        </FormControl>
      </Box>
    );
  };
  console.log({ updateProfile });
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />
        {!userData?.isFetching ? (
          <Center py={5}>
            {userData?.isSuccess ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(values) => {
                  console.log({ values });
                  return (
                    <Form>
                      <Box my={4}>
                        <Center
                          color="#AFAFAF"
                          fontSize="25px"
                          fontWeight="semibold"
                        >
                          Edit Profile
                        </Center>
                        <Divider color="#333" my={4} />
                      </Box>
                      <Stack fontSize="18px" justify="center" spacing={4}>
                        <Stack justify="center" spacing={4}>
                          <Field name="firstName">
                            {({ field, form }) => (
                              <CustomInput
                                field={field}
                                form={form}
                                title="First Name"
                                htmlFor="firstName"
                                error={values.errors.firstName}
                                touched={values.touched.firstName}
                              />
                            )}
                          </Field>
                          <Field name="lastName">
                            {({ field, form }) => (
                              <CustomInput
                                field={field}
                                form={form}
                                title="Last Name"
                                htmlFor="lastName"
                                error={values.errors.lastName}
                                touched={values.touched.lastName}
                              />
                            )}
                          </Field>
                        </Stack>
                        <Center>
                          <Button
                            my={5}
                            colorScheme="facebook"
                            disabled={
                              values?.values?.role?.name !== "BRANCH"
                                ? !(values.isValid && values.dirty)
                                : !(
                                    values.isValid &&
                                    values.dirty &&
                                    values?.values?.branch !== ""
                                  )
                            }
                            _hover={
                              values.isValid &&
                              values.dirty && {
                                opacity: "0.8",
                              }
                            }
                            type={
                              !updateProfile?.isLoading ? "submit" : "button"
                            }
                            isLoading={updateProfile?.isLoading}
                          >
                            Edit Profile
                          </Button>
                        </Center>
                      </Stack>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <Center>Check your connection and refresh </Center>
            )}
          </Center>
        ) : (
          <Center alignSelf={"center"} minH="500px">
            <SyncLoader size={15} margin={2} color="#4962f2" />
          </Center>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ProfileChangeModal;
