import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import StoreManager from "../storemanager/StoreManager";
import Shareholder from "../storemanager/Shareholder/Shareholder";
import Shareholder_detail from "../storemanager/Shareholder/Shareholder_detail";
import DashBoard from "../storemanager/dashboard/Dashboard";
import Share from "../storemanager/Share/Share";
import Dividend from "../storemanager/Dividend/Dividend";
import Transfers from "../storemanager/Transfers/Transfers";
import Payments from "../storemanager/Payments/Payments";
import Createshare from "../storemanager/Shareholder/Createshare";
import CreateStakeholder from "../storemanager/Shareholder/CreateStakeholder";
import Createdividend from "../storemanager/Dividend/Createdividend";
import CreateTransfers from "../storemanager/Transfers/CreateTransfers";
import Createpayments from "../storemanager/Payments/Createpayment";
import CapitalGrowth from "../storemanager/Capital_Growth/CapitalGrowth";
import Createsharegrowth from "../storemanager/Capital_Growth/Createsharegrowth";
import Profit from "../storemanager/Profit/Profit";
import Report from "../storemanager/Report/Report";
// import Createprofit from "../storemanager/Profit/Createprofit";
import Sharerequest from "../storemanager/Request_share/Payments/Sharerequest";
import CreateSharerequest from "../storemanager/Request_share/Payments/CreateSharerequest";
import Createprofit from "../storemanager/Dividend/Createprofit";
import Editepage from "../storemanager/Editepage/Editepage";
import Historypage from "../storemanager/History/Historypage";
import Error from "../storemanager/components/404";
import Meeting from "../storemanager/Meeting/Meeting";
import { Voting } from "../storemanager/Voting/NomineeVoting";
import { Agenda } from "../storemanager/Meeting/Agenda";
import { Proxy } from "../storemanager/Proxy/Proxy";
import { CreateProxy } from "../storemanager/Proxy/CreateProxy";
import CreateDelegate from "../storemanager/Proxy/CreateDelegate";
import { Attendance } from "../storemanager/Meeting/Attendance";
import AddNominee from "../storemanager/Meeting/AddNomine";
import { VoteNominee } from "../storemanager/Voting/VoteNominee";
import { AgendaVoting } from "../storemanager/Voting/AgendaVote/AgendaVoting";
import { VoteAgenda } from "../storemanager/Voting/AgendaVote/VoteAgenda";
import Login from "../Login/Login";
import { UpdateMeeting } from "../storemanager/Meeting/UpdateMeeting";

const StoreManagerRoutes = () => {
  return (
    <div>
      <div>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/" element={<StoreManager />}>
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/Stake-holder" element={<Shareholder />} />
            <Route
              path="/Stake-holder/Createplowback"
              element={<Createshare />}
            />
            <Route path="/dividend" element={<Dividend />} />
            <Route path="/dividend/createprofit" element={<Createprofit />} />
            <Route
              path="/dividend/createDividend"
              element={<Createdividend />}
            />
            <Route path="/transfers" element={<Transfers />} />
            <Route path="/profit" element={<Profit />} />
            <Route path="/profit/createprofit" element={<Createprofit />} />
            <Route path="/Share_request" element={<Sharerequest />} />
            <Route path="/dividend/Edit" element={<Editepage />} />

            <Route
              path="/Share_request/Create_Share_request"
              element={<CreateSharerequest />}
            />

            <Route
              path="/transfers/CreateTransfers"
              element={<CreateTransfers />}
            />
            <Route path="/payments" element={<Payments />} />
            <Route
              path="/payments/Createpayments"
              element={<Createpayments />}
            />
            <Route
              path="/Stake-holder/CreateStakeholder"
              element={<CreateStakeholder />}
            />
            <Route
              path="/Stake-holder/detail/:shareholderId"
              element={<Shareholder_detail />}
            />
            <Route path="/Capital-growth" element={<CapitalGrowth />} />
            <Route
              path="/Capital-growth/createsharegrowth"
              element={<Createsharegrowth />}
            />
            <Route path="/report" element={<Report />} />
            <Route path="/history" element={<Historypage />} />
            <Route path="/meeting" element={<Meeting />} />
            <Route path="/voting" element={<Voting />} />
            <Route path="/voting/:nomineeId" element={<VoteNominee />} />
            <Route path="/agenda-voting" element={<AgendaVoting />} />
            <Route path="/agenda-voting/:agendaId" element={<VoteAgenda />} />
            <Route path="/proxy" element={<Proxy />} />
            <Route path="/meeting/agenda/:meetingId" element={<Agenda />} />
            <Route
              path="/meeting/attendees/:meetingId"
              element={<Attendance />}
            />
            <Route path="/proxy/create-proxy" element={<CreateProxy />} />
            <Route path="/proxy/create-delegate" element={<CreateDelegate />} />
            <Route path="/meeting/:agendaId" element={<AddNominee />} />
            <Route
              path="/meeting/update/:meetingId"
              element={<UpdateMeeting />}
            />
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </div>
      <div />
    </div>
  );
};

export default StoreManagerRoutes;
