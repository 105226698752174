import { Box, HStack, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import Select from "react-select";
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react";
import DetailTable from "./Table/DetailTable";
import { FaUserAlt } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { useAuth } from "../../utilities/hooks/auth-hook";
import Loader from "../../assets/Loader";
import NaBar from "../../utilities/components/NaBar";

export default function Shareholder_detail() {
  const { useState } = React;
  const [selectedOption, setselectedOption] = React.useState([]);
  const [options, setoptions] = React.useState([
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ]);
  const handleChange = (selectedOption) => {
    setselectedOption(selectedOption);
  };

  let navigate = useNavigate();
  const { token } = useAuth();
  const [title, settitle] = useState("");
  const [data1, setData1] = useState([]);

  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [Alldata, setAllData] = useState([]);

  // const {
  //   state: { id },
  // } = useLocation();
  const { shareholderId: id } = useParams();
  console.log({ id });
  const [columns1, setColumns1] = useState([
    {
      title: "Year ",
      field: "year",
    },
    {
      title: "Payable ",
      field: "payable",
    },
    {
      title: "Total payable ",
      field: "total_payable",
    },
  ]);
  const [columns2, setColumns2] = useState([
    {
      title: "Date ",
      field: "created_at",
    },
    {
      title: "Amount ",
      field: "amount",
    },
    {
      title: "Invoice Number ",
      field: "invoice_number",
    },
    {
      title: "Type ",
      field: "type",
    },
  ]);
  const [columns3, setColumns3] = useState([
    {
      title: "Date ",
      field: "created_at",
    },
    {
      title: "From ",
      field: "from.name",
    },
    {
      title: "To",
      field: "to.name",
    },
    {
      title: "No of shares ",
      field: "no_of_shares",
    },
    {
      title: "Type ",
      field: "type",
    },
    {
      title: "Amount ",
      field: "amount",
    },
  ]);
  const [columns4, setColumns4] = useState([
    {
      title: "Start Date ",
      field: "capital_growth.start_date",
    },

    {
      title: "End Date ",
      field: "capital_growth.end_date",
    },

    {
      title: "Persentage ",
      field: "capital_growth.percentage",
    },
    {
      title: "Share Growth ",
      field: "capital_growth.share_growth",
    },

    {
      title: "No of shares ",
      field: "no_of_shares",
    },

    {
      title: "Amount ",
      field: "amount",
    },
  ]);
  const [columns, setColumns] = useState([
    {
      title: "Date ",
      field: "created_at",
    },

    {
      title: "Event ",
      field: "event",
    },

    {
      title: "Amount ",
      field: "amount",
    },
  ]);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  console.log(id);
  const getStakeHolderDetail = useQuery(
    "getStakeHolderDetail",
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}stakeholder-detail/${id}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res.data.data);
        setAllData(res?.data?.data);
        setData1(res?.data?.data?.share_activities);
        setData2(res?.data?.data?.dividends);
        setData3(res?.data?.data?.payments);
        setData4(res?.data?.data?.activities);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  console.log({ getStakeHolderDetail });
  return (
    <Box>
      {getStakeHolderDetail.isFetching ? (
        <Loader />
      ) : (
        <>
          <Avatar size="lg" icon={<FaUserAlt fontSize="2rem" />} my={2}>
            {/* NIB */}
          </Avatar>
          <Stack padding={8} bg={"white"}>
            <HStack>
              {/* <Image
          borderRadius="full"
          boxSize="120px"
          src="https://bit.ly/dan-abramov"
          alt="Dan Abramov"
        /> */}
              <HStack
                align={"start"}
                justify={"space-evenly"}
                spacing={"40px"}
                mx={"auto"}
                width={"100%"}
              >
                <Box textAlign={"left"} color={"#AC7729"}>
                  <Text>Name: {Alldata?.name} </Text>
                  <Text>Phone Number: {Alldata?.phone} </Text>
                  <Text>Share Amount: {Alldata?.share_amount} birr </Text>
                </Box>
                <Box textAlign={"left"} color={"#AC7729"} justify={"left"}>
                  <Text>Bought Shares : {Alldata.bought_shares_count}</Text>
                  <Text>Sold Shares : {Alldata.sold_shares_count}</Text>
                  <Text>Total Share : {Alldata.no_of_shares}</Text>
                </Box>
                <Box textAlign={"left"} color={"#AC7729"} justify={"left"}>
                  <Text>Payable : {Alldata.bought_shares_count}</Text>
                  <Text>Individual Average : {Alldata.sold_shares_count}</Text>
                  <Text>Current Dividend : {Alldata.dividend}</Text>
                </Box>
              </HStack>
            </HStack>
            <br />
            <br />
            <DetailTable
              columns={columns}
              data={data4}
              title={"Activities  "}
            ></DetailTable>
            {/* <HStack ml={8}>
            <Text fontSize={"20px"} color={"#18B933 "}>
              Filter
            </Text>
            <Box w="15vh">
              <Select
                w={"20vh"}
                placeholder="select"
                value={selectedOption}
                onChange={() => {
                  handleChange();
                }}
                options={options}
              />
            </Box>
            <Box w="15vh">
              <Select
                w={"20vh"}
                placeholder="select"
                value={selectedOption}
                onChange={() => {
                  handleChange();
                }}
                options={options}
              />
            </Box>
            <Box w="15vh">
              <Select
                w={"20vh"}
                placeholder="select"
                value={selectedOption}
                onChange={() => {
                  handleChange();
                }}
                options={options}
              />
            </Box>
          </HStack> */}

            {/* <DetailTable
            columns={columns3}
            data={data1}
            title={"Share Activities"}
          ></DetailTable>
          <Stack
            spacing={4}
            direction={{ base: "column", md: "column", lg: "row", xl: "row" }}
          >
            <Box w={"50%"}>
              <DetailTable
                columns={columns1}
                data={data2}
                title={"Dividend"}
              ></DetailTable>
            </Box>
            <Box w={"50%"}>
              <DetailTable
                columns={columns2}
                data={data3}
                title={"Transfers"}
              ></DetailTable>
            </Box> */}
            {/* </Stack> */}
          </Stack>
        </>
      )}
    </Box>
  );
}
