import {
  Box,
  Button,
  Center,
  Stack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import DividendTable from "./Table/DividendTable";
import ProfitTable from "./Table/ProfitTable";
import AverageTable from "./Table/Avaragetable";
export default function Dividend() {
  let navigate = useNavigate();
  return (
    <Stack padding={5}>
      <Tabs>
        <TabList>
          <Tab>Dividend</Tab>
          <Tab>Average</Tab>

          <Tab>Profit</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Center>
              <DividendTable></DividendTable>
            </Center>
          </TabPanel>
          <TabPanel>
            <Center>
              <AverageTable />
            </Center>
          </TabPanel>
          <TabPanel>
            <Stack>
              <Box>
                <Button
                  mt={"20px"}
                  mb={"20px"}
                  ml={"10px"}
                  mr={"20px"}
                  float={"right"}
                  onClick={() => {
                    navigate("/dividend/createprofit", {
                      state: {
                        state: null,
                        isReadOnly: false,
                        isEdit: false,
                        isShow: false,
                      },
                    });
                  }}
                  // w={"30vh"}
                  leftIcon={<AddIcon />}
                  bg={"#B76A1D"}
                  color={"white"}
                  variant="solid"
                >
                  Create Profit
                </Button>
              </Box>
              <Center>
                <ProfitTable></ProfitTable>
              </Center>
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}
