import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Stack,
  Switch,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import FormComp from "../../utilities/components/FormComp";
import * as Yup from "yup";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";
export default function CreateStakeholder() {
  const navigate = useNavigate();
  const { token } = useAuth();
  const toast = useToast();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const sitesMutation = useMutation(
    async (newData) =>
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "stakeholders",
        newData,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );
  const sitesMutationHandler = async (values) => {
    const { name, phone, status, dividend, capital } = values;
    try {
      sitesMutation.mutate(
        { name, phone, status, dividend, capital },
        {
          onSuccess: (responseData) => {
            toast({
              title: "Stakeholder created successfully",
              status: "success",
              duration: 2500,
              isClosable: true,
            });
            navigate("/Stake-holder");
          },
          onError: () => {
            toast({
              title: "Stakeholder not created.",
              status: "error",
              duration: 2500,
              isClosable: true,
            });
          },
        }
      );
    } catch (err) {
      toast({
        title: "Error when Creating",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  return (
    <Stack
      bg={"white"}
      boxShadow={"base"}
      w={"100%"}
      p={8}
      spacing={6}
      justify={"center"}
      align={"center"}
    >
      <Box w={"80%"}>
        <Heading> Create Stakeholder</Heading>
        <Formik
          initialValues={{
            name: "",
            capital: "",
            dividend: "",
            phone: "",
            status: false,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Required !"),
            capital: Yup.string().required("Required !"),
            phone: Yup.string().required("Required !"),
            status: Yup.bool().required("Required !"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            sitesMutationHandler(values);
            console.log({ values });
            setSubmitting(false);
          }}
        >
          {(formik) => {
            console.log({ formik });
            return (
              <form onSubmit={formik.handleSubmit}>
                <FormComp id={"name"} label={"Name"} formik={formik} />

                <FormComp id={"phone"} label={"Phone"} formik={formik} />
                <FormComp
                  id={"capital"}
                  label={"Capital(birr)"}
                  formik={formik}
                />
                <FormComp
                  id={"dividend"}
                  label={"dividend(birr)"}
                  formik={formik}
                />

                <Button
                  disabled={formik.isSubmitting}
                  mt={6}
                  type="submit"
                  bg={"#B76A1D"}
                  color={"white"}
                  variant="solid"
                >
                  Submit
                </Button>
              </form>
            );
          }}
        </Formik>
      </Box>
    </Stack>
  );
}
